import React from 'react';
import { Modal as antdModal } from 'antd';
import CloseIcon from 'components/icons/CloseIcon';
import { MessageType } from 'enums/message';
import { JsObject } from 'interfaces/Object';
import i18n from 'i18n';


export interface ModalBaseOptions {
  onOk?: () => void;
  onCancel?: () => void;
  title?: string;
  header?: string;
  text?: string[] | string;
  okText?: string;
  ok?: string;
  cancel?: string;
  cancelText?: string;
  contentText?: string;
  type?: MessageType;
  width?: string | number;
  hideButtons?: boolean;
}

export type ShowModal = (options?: ModalBaseOptions) => void;

export const showModal: ShowModal = (options) => {
  const { onOk, onCancel, header, text, ok, cancel } = options || {};
  const modal = antdModal.confirm({
    width: 520,
    icon: null,
    className: 'modal-onlytext',
    closable: true,
    centered: true,
    closeIcon: <CloseIcon />,
    title: header,
    okText: ok,
    cancelText: cancel,
    content: <span>{text}</span>,
    onOk: () => {
      onOk?.();
      modal.destroy();
    },
    onCancel: () => {
      onCancel?.();
      modal.destroy();
    },
  });
};


export const confirmSave: ShowModal = (options) => {
  const { onOk, onCancel, cancelText = i18n.t('cancel') } = options || {};
  const modal = antdModal.confirm({
    width: 520,
    icon: null,
    className: 'modal-onlytext',
    closable: true,
    centered: true,
    closeIcon: <CloseIcon />,
    title: i18n.t('saving'),
    okText: i18n.t('save'),
    cancelText,
    content: <span>{i18n.t('saveChanges')}</span>,
    cancelButtonProps: {
        onClick: () => {
            onCancel?.();
            modal.destroy();
        }
    },
    onOk: () => {
      onOk?.();
      modal.destroy();
    },
  });
};

export const showText: ShowModal = (options) => {
  const { title, contentText, hideButtons, width, okText, cancelText } = options || {};
  const type = options?.type || MessageType.INFO;
  const content = <span>{contentText || 'Сообщение отсутствует'}</span>;
  const okButtonProps = hideButtons ? { style: { display: 'none' } } : undefined;

  const modalParams: JsObject = {
    width: width || '40%',
    icon: null,
    className: 'modal-onlytext',
    closable: true,
    maskClosable: true,
    centered: true,
    closeIcon: <CloseIcon />,
    title,
    okText,
    cancelText,
    bodyStyle: { textAlign: 'left' as 'left' },
    okButtonProps,
    content,
  };

  switch (type) {
    case MessageType.SUCCESS: {
      modalParams.title ||= i18n.t('successful');
      antdModal.success(modalParams);
      return;
    }
    case MessageType.INFO: {
      modalParams.title ||= i18n.t('notification');
      antdModal.info(modalParams);
      return;
    }
    case MessageType.WARNING: {
      modalParams.title ||= i18n.t('warning');
      antdModal.warning(modalParams);
      return;
    }
    case MessageType.ERROR: {
      modalParams.title ||= i18n.t('error');
      antdModal.error(modalParams);
      return;
    }
  }

  return;
};

export const showTextSuccess: ShowModal = (options) =>
  showText({
    ...options,
    type: MessageType.SUCCESS,
  });

export const showTextInfo: ShowModal = (options) =>
  showText({
    ...options,
    type: MessageType.INFO,
  });

export const showTextWarning: ShowModal = (options) =>
  showText({
    ...options,
    type: MessageType.WARNING,
  });

export const showTextError: ShowModal = (options) =>

  showText({
    ...options,
    type: MessageType.ERROR,
  });

const modal = {
  showModal,
  confirmSave,
  showText,
  showTextSuccess,
  showTextInfo,
  showTextWarning,
  showTextError,
};

export default modal;
