import ServerAPI from 'integration/ServerAPI';
import ServerError from 'integration/ServerError';
import { AxiosResponseNull } from 'interfaces/AxiosResponseExt';
import { JsObject } from 'interfaces/Object';
import { isNewID } from 'utils/ObjectUtils';

export type GetTabsData = (args: {
  className?: string;
  objectID?: string;
  width?: number;
  useKB?: boolean;
}) => Promise<any>;

export const getTabsData: GetTabsData = async ({ className, objectID, width, useKB }) => {
  const params: JsObject = {
    className,
    useKB: useKB ?? false
  };
  if (!isNewID(objectID)) params.id = objectID;

  const response: AxiosResponseNull = await ServerAPI.getTabsData(params).catch(
    (serverError: ServerError) => serverError.notify(width)
  );

  return response?.data;
};

const TabsCrud = {
  getTabsData,
};

export default TabsCrud;
