import Icon from '@ant-design/icons';

const IconSvg = () => (
  <svg
    width='1.2rem'
    height='1.2rem'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.7869 14.9756C19.6309 13.8174 17.975 13.5044 16.5689 13.9739L10.0391 7.43223C10.5389 5.96109 10.1641 4.33347 9.03928 3.20648C7.97701 2.14227 6.50856 1.79787 5.19646 2.11103C4.94646 2.17357 4.85283 2.45529 5.04025 2.6432L7.10232 4.70904L6.25871 6.30538L4.69647 7.11925L2.6344 5.02198C2.44698 4.83422 2.16575 4.92803 2.10319 5.17848C1.79077 6.58708 2.19697 8.02679 3.19664 9.02831C4.35269 10.1865 5.97735 10.4995 7.41469 10.03L13.9445 16.5717C13.4446 18.0428 13.8507 19.7017 14.9443 20.7974C15.9753 21.8303 17.4438 22.206 18.7871 21.8929C19.0371 21.8303 19.1307 21.5486 18.9433 21.3607L16.8812 19.2948L17.6936 17.7297L19.287 16.9159L21.3491 18.9817C21.5365 19.1695 21.8178 19.0757 21.8803 18.8252C22.224 17.4169 21.8179 15.9771 20.7869 14.9754L20.7869 14.9756Z'
    />
    <path
      d='M10.7564 16.2572C9.16295 16.132 7.88192 14.8174 7.75706 13.2524L6.25738 11.75C5.72617 11.8752 5.22634 11.9065 4.69514 11.8752L4.19531 11.9692C4.0391 12.0004 3.94531 12.1257 3.94531 12.2822V13.9098C3.94531 14.0663 4.0391 14.1915 4.19531 14.2228L4.97635 14.348C5.13256 15.0992 5.445 15.8191 5.88238 16.4764L5.44496 17.1024C5.35117 17.2276 5.38253 17.4153 5.47617 17.5093L6.63222 18.636C6.72601 18.7612 6.91343 18.7612 7.03841 18.6673L7.69445 18.1978C8.35048 18.6047 9.06911 18.8865 9.81893 19.0429L9.94393 19.8254C9.97514 19.9819 10.1001 20.0759 10.2563 20.0759H11.881C12.0372 20.0759 12.1622 19.9506 12.1934 19.8254V19.7315C12.0684 19.0742 12.0996 18.4168 12.2558 17.7596L10.7564 16.2572Z'
    />
    <path
      d='M13.2575 7.74558C14.8509 7.8708 16.1006 9.18543 16.2256 10.7191L17.7565 12.2215C18.2877 12.0963 18.7875 12.065 19.3187 12.0963L19.8185 12.0023C19.9747 11.9711 20.0685 11.8458 20.0685 11.6893V10.0617C20.0685 9.90523 19.9435 9.78001 19.8185 9.74874L18.9125 9.59224C18.7563 8.87229 18.4751 8.18363 18.0689 7.58893L18.5687 6.86898C18.6625 6.74376 18.6312 6.58727 18.5375 6.46205L17.3816 5.36675C17.2878 5.27279 17.1004 5.24153 16.9754 5.33548L16.2568 5.83622C15.632 5.42929 14.9134 5.14756 14.1947 4.99107L14.0697 4.17721C14.0385 4.02071 13.9135 3.92676 13.7573 3.92676H12.1327C11.9764 3.92676 11.8515 4.05198 11.8202 4.17721V4.23975C11.9452 4.89698 11.914 5.5544 11.7578 6.21163L13.2575 7.74558Z'
    />
  </svg>
);

const CustomIcon = (props: any) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
