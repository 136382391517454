import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom';
import styles from './SearchPage.module.css';
import { Pagination, Spin } from 'antd';
import composeLink from 'utils/composeLink';
import ServerAPI from 'integration/ServerAPI';
import { ISearchResult } from 'utils/types';
import notification from 'components/messages/notification';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import SearchBlock, { SearchBlockTitle } from 'components/SearchBlock/SearchBlock';
import {inflate} from 'pako';
import {Trans, useTranslation} from 'react-i18next';

interface ISearchPageProps extends RouteComponentProps<{searchQuery: string, page: string, cascadeFilterBranch: string}> {
  
}

function SearchPage(props: ISearchPageProps) {
  const {searchQuery, page, cascadeFilterBranch} = props.match.params;
  const history = useHistory();
  const LIMIT = 6;

  const [searchResult, setSearchResult] = useState<ISearchResult | null>(null);
  const [searchResultLoading, setSearchResultLoading] = useState(false);
  const {t} = useTranslation();

  document.title = t("search");

  useEffect(() => {
    setSearchResultLoading(true);
    setSearchResult(null);
    const decompressed = inflate(new Uint8Array(cascadeFilterBranch.split(',').map(val => Number(val))), {to: 'string'});
    ServerAPI.axiosFormDataPost('/openSearch/search',
      {
        search: searchQuery,
        branch: JSON.parse(decompressed),
        offset: (Number(page)-1)*LIMIT,
        limit: LIMIT
      }
    ).then((res: AxiosResponseExt) => {
      if (res.data) {
        setSearchResult(res.data);
      }
      setSearchResultLoading(false);
    }).catch((err) => {
      notification.error({
        text: err.options.notificationText
      })
      setSearchResultLoading(false);
    })
  }, [page, searchQuery, cascadeFilterBranch])

  const onPage = (pageNum: number) => {
    history.push(composeLink({search: searchQuery, page: `${pageNum}`, cascadeFilterBranch}))
  }
  return (
    <div className={styles.container}>
      <Spin spinning={searchResultLoading}>
        {
          !!searchResult &&
          <>
            <div className={styles.title}>
                <Trans i18nKey='foundForRequest' values={{request: searchQuery}} components={{span: <span className={styles.secondary}></span>}}/>
            </div>
            <div className={styles.pagination}>
              <Pagination
                simple={true}
                current={Number(page)}
                defaultCurrent={1}
                total={searchResult.count}
                defaultPageSize={LIMIT}
                onChange={onPage}
              />
            </div>
            {
              searchResult.documents.map((document) => (
                <div className={styles.searchBlockWrapper}>
                  <SearchBlock
                    icon={document.icon}
                    link={document.link}
                    highlights={document.highlights}
                    title={document.title}
                  >
                    {
                      !!document.children && document.children.length !== 0 &&
                        document.children.map((child) => (
                          <SearchBlockTitle 
                            highlights={child.highlights}
                            icon={child.icon}
                            link={child.link}
                            title={`${document.title} > ${child.title}`}
                          />
                        ))
                    }
                  </SearchBlock>
                </div>
              ))
            }
          </>
        }
      </Spin>
    </div>
  )
}

export default SearchPage