import React from 'react';
import { Row } from 'antd';
import Title from 'components/layout/content/title/Title';
import inboxOrgPageStyles from './InboxOrgPage.module.css';
import inboxGroupListStyles from './InboxGroupList.module.css';
import aeImage from 'images/aviaExploit.png';

interface InboxImagePageProps {
  inbox: string;
  groupID?: string;
}

const InboxImagePage: React.FC<InboxImagePageProps> = ({ inbox, groupID }) => {
  const src = inbox === 'aviaExploit' ? aeImage : undefined;

  return (
    <>
      <div className={inboxOrgPageStyles.titleWrapper}>
        <Title title={groupID}/>
      </div>
      <Row className={[inboxGroupListStyles.list, 'main-content'].join(' ')}>
        <img src={src} style={{ width: '100%' }} />
      </Row>
    </>
  );
};

export default InboxImagePage;
