import styles from './TimeInput.module.css';
import { Form, InputNumber } from 'antd'
import { BaseComponentProps } from 'interfaces/BaseComponentProps'
import React from 'react'

function TimeInput(props: BaseComponentProps) {
  const onChangeFirst = (value: string | null) => {
    if (props.onChange) {
        props.onChange(`${value ? value : 0}:${props.value ? props.value.split(':')[1] : 0}`);
    }
  }

  const onChangeSecond = (value: string | null) => {
    if (props.onChange) {
        props.onChange(`${props.value ? props.value.split(':')[0] : 0}:${value ? value : 0}`);
    }
  }

  return (
    <div className={styles.container}>
        <Form.Item required={props.ownProps.component?.required} label={props.ownProps.component?.label?.split('=>')[0]}>
            <InputNumber className={styles.input} value={props.value ? props.value.split(':')[0] : ''} onChange={onChangeFirst}/>
        </Form.Item>
        <Form.Item required={props.ownProps.component?.required} label={props.ownProps.component?.label?.split('=>')[1]}>
            <InputNumber className={styles.input} value={props.value ? props.value.split(':')[1] : ''} onChange={onChangeSecond}/>
        </Form.Item>
    </div>
  )
}

export default TimeInput