import React, { MouseEventHandler } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

import RightCircleOutlined from '@ant-design/icons/lib/icons/RightCircleOutlined';
import notification from 'components/messages/notification';

import styles from './SelectControl.module.css';

import composeLink, { LinkParams, nullLink } from 'utils/composeLink';
import MiscUtils from 'utils/MiscUtils';
import { OptionData } from './SelectControl';
import { TransitionType } from 'interfaces/CardTransition';
import { useTranslation } from 'react-i18next';

export interface TransitionButtonProps {
  transition:
    | boolean
    | {
        type?: TransitionType;
        transitionType?: TransitionType;
        className?: string;
      };
  options: OptionData[];
  value: string | number;
  isReadOnly?:boolean;
}

export const TransitionButton: React.FC<TransitionButtonProps> = ({
  transition,
  options,
  value,
  isReadOnly
}) => {
  const [width] = useWindowSize();

  const {t} = useTranslation();
  enum Labels {
    Info = t('goToCard') as unknown as number,
    InfoDisabled = t('impossibleToGoToTheCard') as unknown as number,
    FailMessage = t('unableToOpenCard') as unknown as number,
  }

  const currentOption: OptionData | undefined = options.find(
    (opt: OptionData) => opt.value === value
  );
  const currentOptionParams: any = MiscUtils.jsonParseSafe(currentOption?.key);

  const transitionType: TransitionType | null =
    (typeof transition === 'object' && (transition?.type || transition?.transitionType)) || null;

  const transitionClassName: string =
    (typeof transition === 'object' && transition?.className) ||
    currentOptionParams?.params?.ClassName ||
    '';

  const transitionObjectID: string = currentOptionParams?.params?.ID || '';

  const linkParams: LinkParams = {
    className: transitionClassName,
    id: transitionObjectID,
  };

  const link = transitionClassName && transitionObjectID ? composeLink(linkParams) : nullLink;

  const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (!transitionClassName || !transitionObjectID) {
      notification.error({
        text: Labels.FailMessage as unknown as string,
        screenWidth: width,
      });
    } else if (transitionType === TransitionType.NEW_TAB) {
      event.preventDefault();
      event.stopPropagation();
      window.open(composeLink(linkParams), '_blank')?.focus();
    }
  };

  const disabled = !transitionClassName || !transitionObjectID;

  const containerID = `choice_link_${value}`;

  return (
    <div className={!isReadOnly? styles.iconContainer : ''}>
      {disabled ? (
        <Tooltip
          color={'var(--color-light)'}
          title={Labels.InfoDisabled}
          trigger='hover'
          overlayClassName={styles.tooltip}
        >
          <RightCircleOutlined className={`${styles.iconForTransition} ${styles.iconDisabled}`} />
        </Tooltip>
      ) : (
        <Tooltip
          color={'var(--color-light)'}
          title={Labels.Info}
          trigger='hover'
          overlayClassName={styles.tooltip}
        >
          <Link to={link} id={containerID} key={containerID} onClick={onClick}>
            <RightCircleOutlined className={styles.iconForTransition} />
          </Link>
        </Tooltip>
      )}
    </div>
  );
};

export default TransitionButton;
