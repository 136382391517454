import axios from 'axios';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import buildFormData from 'utils/formData';
import ServerError from './ServerError';
import { JsObject } from 'interfaces/Object';
import keycloak from 'keycloak';

export enum ServerRoute {
  TAB_LAYOUT = '/GetDetailsTabLayout/',
  SEARCH_CONFIG = '/searchConfiguration',
  TABLE_CONFIG = '/GetDataTable',
  TABLE_VIEW = '/TableView',
  GET_CONTENT = '/ContentCrud',
  GET_CONTENT_PREVIEW = '/ContentCrud/preview',
  ATTACH_CONTENT = '/ContentCrud/attachContentToDocument',
  PREFILL_OBJECT = '/Crud/preFillNewObject/',
  GET_OBJECT = '/Crud/getAllObjectInfoByID/',
  CREATE_OBJECT = '/Crud/createObject/',
  UPDATE_OBJECT = '/Crud/updateObject/',
  DELETE_OBJECT = '/Crud/deleteObject/',
  CHOICE_LIST = '/SPChoice/list',
  GET_CHOICE_LABEL = '/SPChoice/getChoiceLabel',
  ON_CHANGE_FIELD = '/OnChangeField',
  GET_TABS = '/GetCaseTabs/',
  GET_TOOLS = '/CaseTool/',
  GET_NEWS = '/Landing/getNewsById',
  GET_MODAL_WINDOW = '/GetModalWindow',
  EXPORT_MESSAGES = '/ExportTableDataReport',
  EXPORT_REGISTRY = '/ExportTableData',
  EXPORT_TEMPLATE = '/GetTemplate',
  IMPORT_OBJECTS = '/ImportObjects',
  DELETE_DRAFT_OBJECT = '/crud/deleteObject',
  ASK_EXPORT_RESULT = '/ExportTableDataReport/askResult',
  UPDATE_PASSWORD = 'UserInfo/updatePassword'
}

export const DefaultErrorMessage: string = 'Не удалось выполнить запрос';

type AxiosParamsGet = (
  controller: string,
  params?: JsObject,
  options?: JsObject
) => Promise<AxiosResponseExt>;

type AxiosParamsPost = (
  controller: string,
  params?: JsObject,
  options?: JsObject
) => Promise<AxiosResponseExt>;

type AxiosFormDataPost = (
  controller: string,
  data?: JsObject | FormData | null,
  options?: JsObject
) => Promise<AxiosResponseExt>;

type ProcessResponse = (response: AxiosResponseExt) => AxiosResponseExt;

type HandleError = (error: ServerError | Error, controller: string) => never;

/**
 * Оборачивает в ServerError добавляя опции
 */
const handleError: HandleError = (error, controller) => {
  console.error(error instanceof ServerError ? error.error : error);
  const notificationText = DefaultErrorMessage;

  throw new ServerError(error, { notificationText });
};

/**
 * Если получена ошибка в теле ответа - выбрасывает Error
 */
const processResponse: ProcessResponse = (response) => {
  if (response.error) throw new ServerError(response.error, { response });

  return response;
};

/**
 * GET запрос с querystring параметрами, в catch - ServerError
 */
export const axiosParamsGet: AxiosParamsGet = (controller, params, options) => {
  return axios
    .get(controller, { params, ...options })
    .then((response: AxiosResponseExt) => processResponse(response))
    .catch((error: ServerError | Error) => handleError(error, controller));
};

/**
 * POST запрос с querystring параметрами, в catch - ServerError
 */
export const axiosParamsPost: AxiosParamsPost = (controller, params, options) => {
  return axios
    .post(controller, undefined, { params, ...options })
    .then((response: AxiosResponseExt) => processResponse(response))
    .catch((error: ServerError | Error) => handleError(error, controller));
};

/**
 * POST запрос с FormData, data автоматически обернется в FormData, в catch - ServerError
 */
export const axiosFormDataPost: AxiosFormDataPost = (controller, data, options) => {
  return axios
    .post(controller, data ? buildFormData(data) : undefined, options)
    .then((response: AxiosResponseExt) => processResponse(response))
    .catch((error: ServerError | Error) => handleError(error, controller));
};

// GET запросы с querystring параметрами
export const [getDetailsTabLayout, getSearchConfig, deleteObject, getTabsData] = [
  ServerRoute.TAB_LAYOUT,
  ServerRoute.SEARCH_CONFIG,
  ServerRoute.DELETE_OBJECT,
  ServerRoute.GET_TABS,
].map(
  (controller: string) => (params?: JsObject, options?: JsObject) =>
    axiosParamsGet(controller, params, options)
);

// POST запросы с querystring параметрами
export const [getModalWindow] = [ServerRoute.GET_MODAL_WINDOW].map(
  (controller: string) => (params?: JsObject, options?: JsObject) =>
    axiosParamsPost(controller, params, options)
);

// change keycloak locale
export const changeKeycloakLocale = async (locale: string) => {
  return fetch(`${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/endpoint-customer/changeLocale?locale=${locale}`,
  {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    }
  }).then((res) => res.text())
}

// POST запросы с FormData, data автоматически обернется в FormData
export const [
  getTableConfig,
  getTableItems,
  getFiles,
  getFilesPreview,
  attachFiles,
  preFillNewObject,
  getObjectInfoById,
  createObject,
  updateObject,
  getOnChangeData,
  getToolsData,
  getNewsById,
  exportMessages,
  exportRegistry,
  exportTemplate,
  importObjects,
  getChoiceLabel,
  deleteDraftObject,
  askExportResult,
  updatePassword
] = [
  ServerRoute.TABLE_CONFIG,
  ServerRoute.TABLE_VIEW,
  ServerRoute.GET_CONTENT,
  ServerRoute.GET_CONTENT_PREVIEW,
  ServerRoute.ATTACH_CONTENT,
  ServerRoute.PREFILL_OBJECT,
  ServerRoute.GET_OBJECT,
  ServerRoute.CREATE_OBJECT,
  ServerRoute.UPDATE_OBJECT,
  ServerRoute.ON_CHANGE_FIELD,
  ServerRoute.GET_TOOLS,
  ServerRoute.GET_NEWS,
  ServerRoute.EXPORT_MESSAGES,
  ServerRoute.EXPORT_REGISTRY,
  ServerRoute.EXPORT_TEMPLATE,
  ServerRoute.IMPORT_OBJECTS,
  ServerRoute.GET_CHOICE_LABEL,
  ServerRoute.DELETE_DRAFT_OBJECT,
  ServerRoute.ASK_EXPORT_RESULT,
  ServerRoute.UPDATE_PASSWORD,
].map(
  (controller: string) => (data?: JsObject | FormData | null, options?: JsObject) =>
    axiosFormDataPost(controller, data, options)
);

const ServerAPI = {
  // general
  axiosParamsGet,
  axiosParamsPost,
  axiosFormDataPost,
  // get - queryparams
  getDetailsTabLayout,
  getSearchConfig,
  deleteObject,
  getTabsData,
  // post - queryparams
  getModalWindow,
  // post - formdata
  getTableConfig,
  getTableItems,
  getFiles,
  getFilesPreview,
  attachFiles,
  preFillNewObject,
  getObjectInfoById,
  createObject,
  updateObject,
  getOnChangeData,
  getToolsData,
  getNewsById,
  exportMessages,
  exportRegistry,
  exportTemplate,
  importObjects,
  getChoiceLabel,
  deleteDraftObject,
  askExportResult,
  changeKeycloakLocale,
  updatePassword
};

export default ServerAPI;
