import React, { useEffect, useRef, useState } from 'react';
import dataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';
import stylesRequestChart from './RequestsChart.module.css';
import styleTooltip from '../entityList/EntityList.module.css';
import stylesMultiPieChart from './MultiPieChart.module.css';
import styles from './FloatingChart.module.css';

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js';
import { Tooltip } from 'antd';
import { valueForPeriod } from './utils';
import { ItemDataTooltip, ItemValueForPeriod, RowItem } from 'utils/types';
import { getPopupContainer } from '../../components/controls/SelectControl/SelectControl';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

interface RequestsChartProps {
  width: number;
  dataFloatingChart: any[];
  dataTooltip: ItemDataTooltip[];
  period: string;
}

export const handleFontSize = (width: number): number => {
  if (width >= 2560) {
    return 20;
  } else if (width <= 2559 && width >= 1950) {
    return 20;
  } else if (width <= 1949 && width >= 1500) {
    return 15;
  } else if (width <= 1499 && width >= 200) {
    return 10;
  } else {
    return 10;
  }
};

const FloatingChart: React.FC<RequestsChartProps> = (props) => {
  const ref = useRef<any>();
  const [chart, setChart] = useState<Chart>();
  const [date, setDate] = useState<string>('');
  const { t } = useTranslation();

  const style = getComputedStyle(document.body);
  const colorBright = style.getPropertyValue('--color-bright')
  const colorBright2 = style.getPropertyValue('--color-bright-2');
  const colorBright3 = style.getPropertyValue('--color-bright-3');
  const colorBright4 = style.getPropertyValue('--color-bright-4');
  const colorBright5 = style.getPropertyValue('--color-bright-5');
  const colorBright10 = style.getPropertyValue('--color-bright-10');
  const colorSecondary = style.getPropertyValue('--color-secondary');
  const colorDark = style.getPropertyValue('--color-dark');
  const colorDark4 = style.getPropertyValue('--color-dark-2');
  
  const { period, width, dataFloatingChart, dataTooltip } = props;

  // TO-DO: временный хардкод для водопада, заглушка пока сервер не будет возвращать корректные данные
  // dataIntegralIndicators[0].headerValue.replace(/\/\s\d+/g, '') - всего в работе
  // dataIntegralIndicators[0].rows[0].values[0] - открыто на начало периода
  // dataIntegralIndicators[0].rows[1].values[0] - Поступило запросов
  // dataIntegralIndicators[2].rows[0].values[0] - обработано в срок
  // dataIntegralIndicators[2].rows[1].values[0] - обработано не в срок
  // dataIntegralIndicators[4].rows[0].values[0] - Не обработано, срок не истек
  // dataIntegralIndicators[4].rows[1].values[0] -Не обработано, просрочено

  const data: ChartData = {
    labels: [' ', ' ', ' ', ' ', ' ', ' ', ' '],
    datasets: [
      {
        label: 'Dataset 1',
        data: dataFloatingChart,
        backgroundColor: [
          colorBright2,
          colorBright3,
          colorBright4,
          colorBright5,
          colorBright,
          colorSecondary,
          colorBright10,
        ],
        borderColor: colorDark4,
        borderWidth: 1,
      },
    ],
  };

  const handlePeriod = (): void => {
    valueForPeriod(t).forEach((item: ItemValueForPeriod) => {
      if (period === item.value) {
        setDate(item.label);
      }
    });
  };

  useEffect(() => {
    if (ref.current != null && !chart) {
      Chart.register([
        LineController,
        LineElement,
        PointElement,
        LinearScale,
        CategoryScale,
        BarController,
        BarElement,
        dataLabels,
      ]);
      const chartInstance: Chart = new Chart(ref.current, {
        type: 'bar',
        plugins: [
          {
            id: 'customPlugin',
            beforeDraw: (chart, args, options) => {
              const {
                ctx,
                config,
                scales: { x, y },
              } = chart;
              ctx.save();
              ctx.strokeStyle = colorDark4;

              for (
                let i = 0;
                // @ts-ignore
                i <= config._config.data.datasets[0].data.length - 2;
                i++
              ) {
                ctx.strokeRect(
                  x.getPixelForValue(i),
                  y.getPixelForValue(
                    // @ts-ignore
                    config._config.data.datasets[0].data[i][1]
                  ),
                  x.getPixelForValue(0.4),
                  0
                );
              }
              ctx.restore();
            },
          },
        ],
        data,
        options: {
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
            datalabels: {
              formatter(value: number[]) {
                return String(value[1] - value[0]).replace('-', '');
              },
              color: colorDark,
              font: {
                size: handleFontSize(width),
              },
            },
          },
          scales: {
            y: {
              ticks: {
                font: {
                  size: handleFontSize(width),
                },
                color: colorDark,
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
          responsive: true,
        },
      });
      setChart(chartInstance);
    }
  }, [ref]);

  useEffect(() => {
    if (chart) {
      chart.data = data;
      chart.update();
    }
  }, [chart, dataFloatingChart, dataTooltip]);

  useEffect(() => {
    handlePeriod();
  }, [period]);

  const handleColor = (label: string): string => {
    let color: string = '';
    if (label === t('openAtBeginningOfPeriod')) {
      color = 'var(--color-bright-2)';
    } else if (label === t('createdRequests')) {
      color = 'var(--color-bright-3)';
    } else if (label === t('finishedOnTime')) {
      color = 'var(--color-bright-5)';
    } else if (label === t('finishedOutOfTime')) {
      color = 'var(--color-bright)';
    } else if (label === t('notFinishedProcessedOnTime')) {
      color = 'var(--color-secondary)';
    } else if (label === t('incompleteOutOfTime')) {
      color = 'var(--color-bright-10)';
    } else {
      color = '';
    }
    return color;
  };

  return (
    <div className={`${stylesRequestChart.requestChart} js-parentNode`}>
      <div className={stylesRequestChart.wrapChart}>
        <div className={stylesRequestChart.titleLeft}>{t('numberOfRequests')}</div>
        <Tooltip
          title={
            <div className={styles.tooltip}>
              <div className={styles.date}>{date}</div>
              <div className={styles.wrapIntegralIndicators}>
                {dataTooltip.map((item: ItemDataTooltip, b: number) => {
                  if (
                    item.headerTitle.split(/\,/)[0] === t('totalRequests') ||
                    item.headerTitle.split(/\,/)[0] === t('finished') ||
                    item.headerTitle.split(/\,/)[0] === t('notFinished')
                  ) {
                    return (
                      <React.Fragment key={`item_${b}`}>
                        <div className={styles.wrapperItem} key={`item_${b}`}>
                          <div className={styles.titleWrap}>
                            <div className={styles.wrapGroupTitle}>{`${item.headerTitle}`}</div>
                            <div
                              className={stylesMultiPieChart.value}
                            >{`${item.value} ${t('piece')}/ ${item.valuePercent} %`}</div>
                          </div>
                        </div>
                        {item.rows.map((elem: RowItem, i: number) => {
                          if (
                            elem.label === 'Закрыто' ||
                            elem.label === 'Отказано' ||
                            elem.label === 'Отменено'
                          ) {
                            return;
                          } else {
                            return (
                              <div key={`elem_${i}`}>
                                <div className={styles.titleWrap}>
                                  <div className={styles.wrapColorAndLabel}>
                                    <div
                                      className={stylesRequestChart.color}
                                      style={{
                                        backgroundColor: handleColor(elem.label),
                                      }}
                                    />
                                    {` ${elem.label}`}
                                  </div>
                                  <div
                                    className={stylesMultiPieChart.value}
                                  >{`${elem.values[0]}/${elem.percent[0]}`}</div>
                                </div>
                                {item.columns.map((column: string, a: number) => {
                                  if (column !== t('total')) {
                                    return (
                                      <div className={styles.wrapPriority} key={`column_${a}`}>
                                        <div
                                          className={stylesMultiPieChart.wrapColorAndLabel}
                                        >{` ${column}`}</div>
                                        <div
                                          className={stylesMultiPieChart.value}
                                        >{`${elem.values[a]}/${elem.percent[a]}`}</div>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            );
                          }
                        })}
                      </React.Fragment>
                    );
                  }
                })}
              </div>
            </div>
          }
          color='var(--color-light)'
          placement='rightTop'
          getPopupContainer={getPopupContainer}
          overlayClassName={`${styleTooltip.tooltip} ${stylesMultiPieChart.reportTooltip}`}
        >
          <canvas className={stylesRequestChart.chart} ref={ref} />
        </Tooltip>
      </div>
      <div className={stylesRequestChart.legend}>
        <div className={stylesRequestChart.container}>
          <div className={stylesRequestChart.color} style={{ backgroundColor: 'var(--color-bright-2)' }} />
          <div className={stylesRequestChart.text}>{t('openAtBeginningOfPeriod')}</div>
          <div className={stylesRequestChart.color} style={{ backgroundColor: 'var(--color-bright-3)' }} />
          <div className={stylesRequestChart.text}>{t('createdRequests')}</div>
          <div className={stylesRequestChart.color} style={{ backgroundColor: 'var(--color-bright-4)' }} />
          <div className={stylesRequestChart.text}>{t('totalRequests')}</div>
          <div className={stylesRequestChart.color} style={{ backgroundColor: 'var(--color-bright-5)' }} />
          <div className={stylesRequestChart.text}>{t('finishedOnTime')}</div>
        </div>
        <div className={stylesRequestChart.containerLineTwo}>
          <div className={stylesRequestChart.color} style={{ backgroundColor: 'var(--color-bright)' }} />
          <div className={stylesRequestChart.text}>{t('finishedOutOfTime')}</div>
          <div className={stylesRequestChart.color} style={{ backgroundColor: 'var(--color-secondary)' }} />
          <div className={stylesRequestChart.text}>{t('notFinishedProcessedOnTime')}</div>
          <div className={stylesRequestChart.color} style={{ backgroundColor: 'var(--color-bright-10)' }} />
          <div className={stylesRequestChart.text}>{t('incompleteOutOfTime')}</div>
        </div>
      </div>
    </div>
  );
};
export default FloatingChart;
