import React from 'react';
import EngineeringTechnicalRequestIcon from 'components/icons/EngineeringTechnicalRequestIcon';
import EDRequestIcon from 'components/icons/EDRequestIcon';
import LinkedZkPIcon from 'components/icons/case/LinkedZkPIcon';
import styles from './InboxGroupList.module.css';
import stylesEntityList from '../entityList/EntityList.module.css';

export const getInboxGroupToolIcon = (icon?: string): React.ReactNode => {
  switch (icon) {
    case 'etr': {
      return <EngineeringTechnicalRequestIcon className={styles.icon} />;
    }
    case 'ed': {
      return <EDRequestIcon className={styles.icon} />;
    }
    case 'LinkedZkPIcon': {
      return <LinkedZkPIcon className={stylesEntityList.iconButton}/>;
    }
  }
  return null;
};
