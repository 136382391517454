import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState, useContext, MutableRefObject } from 'react';
import notification from 'components/messages/notification';
import { cloneDeep } from 'lodash';
import { useTableViewAddComponents } from 'components/TableView/hooks/useTableViewAddComponents';

import EntityEditorContext, {
  EntityEditorContextData,
} from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import ControlFactory from 'utils/ControlFactory';
import FormatUtils from 'utils/FormatUtils';
import composeLink from 'utils/composeLink';
import { buildFormData } from 'utils/formData';
import { isNewID } from 'utils/ObjectUtils';
import { ISort } from 'interfaces/Table';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import { useTranslation } from 'react-i18next';
import { ActionType } from 'store/actionTypes';

interface UseLoadArgsType {
  setTableConfiguration: any;
  dispatch: any;
  updateColumns: any;
  isKnowledgeBase: any;
  isExternalUser: any;
  setFiltersConfiguration: any;
  setFactory: any;
  filtersConfiguration: any;
  tableConfiguration: any;
  setCount: any;
  setItems: any;
  offset: any;
  limit: any;
  sort: ISort;
  items: any;
  setOffset: any;
  setIsScrollEnd: any;
  setShowFiltersTools: any;
  readyToLoad: boolean;
  componentData: any;
  filtersConfigLoadingRef: MutableRefObject<boolean>;
}

export type DoSearch = (config?: any, append?: boolean) => Promise<void>;
export type LoadTableConfigs = (objectId?: string) => Promise<void>;

interface UseLoadReturnsType {
  loading: boolean | null;
  doSearch: DoSearch;
  loadTableConfigs: LoadTableConfigs;
}

type UseLoadType = (args: UseLoadArgsType) => UseLoadReturnsType;

export const useTableViewLoad: UseLoadType = ({
  setTableConfiguration,
  dispatch,
  updateColumns,
  isKnowledgeBase,
  isExternalUser,
  setFiltersConfiguration,
  setFactory,
  filtersConfiguration,
  tableConfiguration,
  setCount,
  setItems,
  offset,
  limit,
  sort,
  items,
  setOffset,
  setIsScrollEnd,
  setShowFiltersTools,
  readyToLoad,
  componentData,
  filtersConfigLoadingRef,
}) => {
  const {
    inboxName,
    meta,
    className,
    objectID,
    conditionId,
    fillCriteriaFrom,
    isCardTable,
    inputSearchValue,
    transitionType,
    modalType,
    simpleTable,
    useKB,
    choiceLists,
    inboxViewName,
    searchName,
    parentId,
    parentClassName,
  } = componentData;

  const editorContext = useContext<EntityEditorContextData>(EntityEditorContext);

  // null нужен как третье состояние - двух true / false не хватает
  // loading === null в самом начале, когда ещё ничего не подгрузилось
  // но визуально таблица должна быть в загрузке
  // для нового объекта таблицы не подгружаются - loading сразу false
  const [loading, setLoading] = useState<boolean | null>(
    isCardTable && isNewID(objectID) ? false : null
  );

  // сбрасывается loading при смене реестра
  useEffect(() => {
    if (!isCardTable) setLoading(null);
  }, [inboxName]);

  const { addTableComponents, addFilterComponents, bypassNestedFilters } =
    useTableViewAddComponents(meta, setShowFiltersTools);

  const doSearch: DoSearch = async (config, append = false) => {
    const searchConfig: any = !config ? filtersConfiguration : config;
    if (
      loading ||
      !readyToLoad ||
      !searchConfig ||
      (className && className !== searchConfig.className)
    )
      return;

    setLoading(true);
    let noData: boolean = false;

    // process conditions before sending
    config?.config.criteria.conditions.forEach((field: any) => {
      // cardTable condition value injection
      if (field.id === conditionId && fillCriteriaFrom && isCardTable) {
        const value = editorContext.form?.getFieldValue(fillCriteriaFrom);
        noData = !value?.length;
        field.value = value;
      }

      try {
        if (field.value === JSON.parse(field.jsonConfig).defaultValue) field.value = null;
      } catch {
        /* no-op */
      }

      // wrap value in array for operators that need array
      const arrayOperators = [
        'CONTAINS',
        'CONTAINED_BY',
        'EQUAL_ALL',
        'NOT_EQUAL_ALL',
        'INTERSECTS',
        'ALL_IN',
        'ALL_NOT_IN',
        'OVERLAP',
      ];
      if (
        arrayOperators.includes(field.condition?.name?.toUpperCase()) &&
        !Array.isArray(field.value) &&
        field.value !== undefined &&
        field.value !== null
      ) {
        field.value = [field.value];
      }

      return field;
    });

    const emptyTable = (): void => {
      setCount({ count: 0, openCount: 0, closedCount: 0 });
      setItems([]);
    };

    if (noData) {
      emptyTable();
      setLoading(false);
      return;
    }

    const bodyFormData = buildFormData({
      search: JSON.stringify(config.config),
      text: inputSearchValue,
      limit: String(limit),
      offset: `${append ? offset + (append ? limit : 0) : 0}`,
      sortColumn: sort.nameColumn,
      orderDirection: sort.orderDirection,
      inboxViewName,
      inboxName,
    });
    try {
      const searchResponse: AxiosResponseExt = await axios.post('/TableView', bodyFormData);

      if (searchResponse?.data?.count && searchResponse?.data?.sortedObjects) {
        setCount(searchResponse.data.count);
        const newItems: any[] = searchResponse.data.sortedObjects.map(
          (itemData: any, idx: number) => {
            const systemProps: any = {
              key: idx,
              '.transitionType': transitionType,
              '.fillCriteriaFrom': fillCriteriaFrom,
              '.modalType': modalType,
              '.link': !simpleTable
                ? composeLink({
                  inbox: inboxName,
                  className: itemData.classType,
                  id: itemData.id,
                  useKB,
                })
                : undefined,
              parentClassType: parentClassName
            };
            return FormatUtils.createTableRecord(
              itemData,
              tableConfiguration,
              choiceLists,
              systemProps
            );
          }
        );

        const itemsFinal = append ? cloneDeep(items.concat(newItems)) : newItems;

        itemsFinal.forEach((i: any, idx: number, target: any) => {
          target[idx] = { ...i, key: i.id };
        });

        if (append) {
          setItems(itemsFinal);
          setOffset(offset + limit);
        } else {
          setItems(itemsFinal);
          setOffset(0);
        }
      } else {
        emptyTable();
      }
    } catch (e) {
      console.error(e);
      emptyTable();
    }
    setLoading(false);
    setIsScrollEnd(false);
  };
  const {t} = useTranslation();
  const loadTableConfigs: LoadTableConfigs = useCallback(async (objectId?: string) => {
    try {
      const getDataTableParams = isCardTable ? { inboxViewName, className } : { inboxName };
      const { data: tableData }: AxiosResponse = await axios.post(
        '/GetDataTable',
        buildFormData(getDataTableParams)
      );
      const searchConfigurationParams = isCardTable
        ? {
          className,
          searchName,
          parentId: parentId ? parentId : objectID,
          parentClassType: parentClassName,
        }
        : { folder: inboxName };
      const { data: filtersData }: AxiosResponse = await axios.get('/searchConfiguration', {
        params: searchConfigurationParams,
      });

      setTableConfiguration(tableData);
      const factory = new ControlFactory(dispatch);

      bypassNestedFilters(filtersData.criteria.conditions, tableData, factory);
      addTableComponents(tableData, factory);

      const newFiltersConfig: any = {
        className,
        config: cloneDeep(filtersData),
        initConfig: cloneDeep(filtersData),
      };

      updateColumns(tableData, isKnowledgeBase, isExternalUser, newFiltersConfig);

      setFiltersConfiguration(newFiltersConfig);
      dispatch({
        type: ActionType.SET_SEARCH_CONFIGURATIONS,
        payload: {
          key: className,
          config: newFiltersConfig
        }
      })
      addFilterComponents(newFiltersConfig, factory);
      filtersConfigLoadingRef.current = false;

      await factory._fetchChoiceListData().then(() => setFactory(factory));
    } catch (e) {
      notification.error({
        text: t('unableToLoadConfiguration'),
      });
    }
  }, [
    inboxName,
    className,
    searchName,
    parentId,
    parentClassName,
    isExternalUser,
    isKnowledgeBase,
  ]);

  return { loading, doSearch, loadTableConfigs };
};
