import { Collapse } from 'antd';
import React from 'react'
import styles from './SearchBlock.module.css';
import { SearchIconsEnum } from 'utils/types';
import ETRIcon from 'components/icons/search/ETRIcon';
import ETRMessageIcon from 'components/icons/search/ETRMessageIcon';
import ZNKEDAttachIcon from 'components/icons/search/ZNKEDAttachIcon';
import ZNKEDIcon from 'components/icons/search/ZNKEDIcon';
import CollpaseIcon from 'components/icons/search/CollpaseIcon';

interface ISearchBlockProps extends ISearchBlockTitleProps {
  children?: React.ReactNode;
}

interface ISearchBlockTitleProps {
  title: string;
  icon: SearchIconsEnum;
  highlights: {
    [key: string]: string;
  }
  link?: string;
}

function SearchBlockIcon(icon: SearchIconsEnum) {
  switch (icon) {
    case SearchIconsEnum.ETRIcon:
      return <ETRIcon />

    case SearchIconsEnum.ETRMessageIcon:
      return <ETRMessageIcon />

    case SearchIconsEnum.ZNKEDAttachIcon:
      return <ZNKEDAttachIcon />

    case SearchIconsEnum.ZNKEDIcon:
      return <ZNKEDIcon />
  
    default:
      break;
  }
}

export function SearchBlockTitle({highlights, icon, link, title}: ISearchBlockTitleProps) {
  const Title = !!link ? 'a' : 'span';
  return (
    <div className={`${styles.panelWrapper}`}>
      <div className={styles.title}>
        <div className={styles.icon}>
          {SearchBlockIcon(icon)}
        </div>
        <Title href={`${window.location.origin}${link}`} className={styles.titleLink} target='_blank'>
          {title}
        </Title>
      </div>
      <div className={styles.highlights}>
        {
          Object.keys(highlights).map((title) => (
            <>
            <span>
              <span className={styles.highlightTitle}>{title}: </span> <span className={styles.highlightText} dangerouslySetInnerHTML={{__html: highlights[title]}}></span>
            </span>&#160;&#160;
            </>
          ))
        }
      </div>
    </div>
  )
}

function SearchBlock({icon, link, highlights, title, children}: ISearchBlockProps) {
  return (
    <div className={styles.collapseWrapper}>
      <Collapse
        collapsible={!!children ? 'icon' : 'disabled'}
        expandIcon={({ isActive }) => (
          <>
          {
            !!children &&
            <div className={styles.collapseIcon}>
              <CollpaseIcon isActive={isActive === true} />
            </div>
          }
          </>
        )}
        ghost={true}
      >
        <Collapse.Panel
          header={<SearchBlockTitle highlights={highlights} icon={icon} link={link} title={title}/>}
          key={'panel'}
        >
          <div className={styles.childrenWrapper}>
            {children}
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

export default SearchBlock