import notification from 'components/messages/notification';
import { AxiosResponseNull } from 'interfaces/AxiosResponseExt';

export const processExportResponse = (response: AxiosResponseNull) => {
  if (response?.data) {
    const element = document.createElement('a');
    element.setAttribute('href', `${response.data.file}`);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    notification.success({
      text: response.data.message,
    });
  }
};