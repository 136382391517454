export interface Action<E, T> {
  type: E;
  payload?: T;
}

export enum ActionType {
  GLOBAL_LOADER = 'globalState/update',
  UPDATE_APP_SETTINGS = 'settings/update',
  UPDATE_META_DATA = 'meta/update',
  UPDATE_MENU_DATA = 'menu/update',
  UPDATE_EDITOR_FORM = 'editor/form/update',
  UPDATE_EDITOR_RESPONSES_INFO = 'editorResponses/update',
  UPDATE_EDITOR_ERRORS = 'editorErrors/update',
  UPDATE_EDITOR_DETAILS = 'editorDetails/update',
  UPDATE_EDITOR_NOTIFICATIONS = 'editorDetails/notifications',
  UPDATE_EDITOR_REFRESH_TIME = 'editor/refreshTime/update',
  UPDATE_EDITOR_DISABLED_TABLE_CONTROLS = 'editor/disabledTableControls/update',
  UPDATE_EDITOR_DISABLED_DATEPICKER = 'editor/disabledDatePicker/update',
  UPDATE_EDITOR_ALL_BUTTONS = 'editorButtons/editorButtons/update(all)',
  UPDATE_EDITOR_BUTTONS = 'editorButtons/editorButtons/update(one)',
  UPDATE_EDITOR_TAB = 'editorButtons/currentTab/update',
  REMOVE_EDITOR_FILES = 'editorFiles/remove',
  UPDATE_EDITOR_FILES = 'editorFiles/update',
  CLEAR_EDITOR_FILES = 'editorFiles/clear',
  UPDATE_EDITOR_FILE_STATUS = 'editorFiles/status/update',
  UPDATE_FILTERS = 'filters/update',
  IS_CHECKED_FILTERS = 'checkedFilters/isCheckedFilters',
  REMOVE_FILTERS = 'filters/remove',
  UPDATE_USER = 'user/update',
  UPDATE_CHOICE_LISTS = 'choiceLists/update',
  UPDATE_TABLE_DATA = 'tableData/update',
  UPDATE_TABLE_STATE = 'tableData/tableState/update',
  UPDATE_TABLE_MODAL_DATA = 'tableData/tableModalData/update',
  UPDATE_CRUMBS = 'crumbs/update',
  CRUMBS_SET_ACTIVE_CHAIN_ID = 'crumbs/activeChainId/set',
  CLEAR_CRUMBS = 'crumbs/clear',
  INIT_CRUMBS = 'crumbs/init',
  GET_TABLE_DATE = 'tableDate/get',
  GET_TABLE_COLUMNS = 'tableDate/tableColumns/get',
  UPDATE_SEARCH_CONFIGURATIONS = 'filtersConfiguration/update',
  SET_SEARCH_CONFIGURATIONS = 'searchConfigurations/set',
  TYPE_USER = 'typeUser/set',
  UPDATE_USER_DATA = 'typeUser/userData/update',
  SET_IS_LOCALE_INITED = 'typeUser/userData/locale',
  UPDATE_MODAL_DATA = 'modal/update',
  RESET_MODAL_DATA = 'modal/reset',
  UPDATE_SELECTED_ROWS = 'selectedRows/update',
  CLEAR_SELECTED_ROWS = 'selectedRows/clear',
  REFRESH_COMPONENT = 'refresh/component',
  REFRESH_COMPONENT_RESET = 'refresh/reset',
  // REFRESH_FILES,
  // UPDATE_EDITOR_DETAILS_GUID,
  // UPDATE_EDITOR_DETAILS_PARAMS_COPY,
  // GET_FILTER_CONFIG,
  // UPDATE_HISTORY_FILTERS,
  // GET_LINKED_NEW_FILTERS,
  // UPDATE_LINKED_NEW_FILTERS,
}
