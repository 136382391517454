import CreateRequestIcon from 'components/icons/case/CreateRequestIcon';
import CreateReportIcon from 'components/icons/case/CreateReportIcon';
import LinkedEDChangeRequestTabIcon from 'components/icons/case/LinkedEDChangeRequestTabIcon';
import ChatIcon from 'components/icons/case/ChatIcon';
import CheckLogIcon from 'components/icons/case/CheckLogIcon';
import LinkedZkPIcon from 'components/icons/case/LinkedZkPIcon';
import DownloadIcon from 'components/icons/case/DownloadIcon';
import DownloadLogIcon from 'components/icons/case/DownloadLogIcon';
import LinkedIcon from 'components/icons/case/LinkedIcon';
import LogIcon from 'components/icons/case/LogIcon';
import StatusIcon from 'components/icons/case/StatusIcon';
import TechnicalRequestTimeStampTabIcon from 'components/icons/TechnicalRequestTimeStampTabIcon';

const icons: any = {
  createRequest: CreateRequestIcon,
  createReport: CreateReportIcon,
  chat: ChatIcon,
  checkLog: CheckLogIcon,
  download: DownloadIcon,
  downloadLog: DownloadLogIcon,
  linked: LinkedIcon,
  LinkedEngineeringTechnicalRequestTab: LinkedIcon,
  LinkedEDChangeRequestForETRTab: LinkedEDChangeRequestTabIcon,
  log: LogIcon,
  history: CheckLogIcon,
  EngineeringTechnicalRequestResponceTab: LogIcon,
  details: StatusIcon,
  status: StatusIcon,
  LinkedEDChangeRequestTab: LinkedEDChangeRequestTabIcon,
  LinkedRequestTab: LinkedEDChangeRequestTabIcon,
  EDTechnicalRequestTimeStamp: TechnicalRequestTimeStampTabIcon,
  TechnicalRequestTimeStampTab: TechnicalRequestTimeStampTabIcon,
  RTSTimeStampTab: TechnicalRequestTimeStampTabIcon,
  ReclamationActTimeStamp: TechnicalRequestTimeStampTabIcon,
  ReclamationActTimeStampExternal: TechnicalRequestTimeStampTabIcon,
  EDTechnicalRequestTimeStampExternal: TechnicalRequestTimeStampTabIcon,
  EngineeringTechnicalRequestTechnicalRequestTimeStampExternalUser:
    TechnicalRequestTimeStampTabIcon,
  LinkedRequestsToSuppliersTabETR: LinkedZkPIcon,
  LinkedRequestsToSuppliersTab: LinkedZkPIcon,
  LinkedRequestsToSuppliersTabED: LinkedZkPIcon,
  };

export default icons;
