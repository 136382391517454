import React, { useEffect, useMemo, useState } from 'react';
import { Row, Dropdown, Button } from 'antd';
import styles from './InboxOrgPage.module.css';
import inboxGroupListStyles from './InboxGroupList.module.css';
import stylesEntityList from '../entityList/EntityList.module.css';
import mtoImage from 'images/mto.svg';
import mtoCSDPImage from 'images/mto.csdp.svg';
import axios from 'axios';
import SectionContact from 'components/sectionContact/SectionContact';
import Title from 'components/layout/content/title/Title';
import { Contact } from 'utils/types';
import notification from 'components/messages/notification';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import { getInboxGroupToolIcon } from './utils';

interface InboxStartPageProps {
  inbox: string;
  groupID?: string;
}

interface SubListItem {
  title?: string;
  subtitle?: string;
  url?: string;
}

interface OrgData {
  logo?: {
    image?: string;
    link?: string;
  };
  title?: string;
  about?: string;
  sublist?: {
    title?: string;
    data?: SubListItem[];
  };
  contacts?: Contact;
}

interface ToolData {
  label: string;
  action: 'choice';
  icon: string;
  choiceItems?: {
    title: string;
    code: string | number;
  }[];
}

interface PageData {
  pageTitle?: string;
  tools?: ToolData[];
  defaultRecordCode?: string | number;
  recordList?: Record<string, OrgData>;
  record?: OrgData;
}

const extractOrgData = (pageData?: PageData, orgCode?: string | number): OrgData | undefined => {
  if (!pageData) return;

  if (pageData.record) return pageData.record;

  if (orgCode === undefined) {
    orgCode = pageData.defaultRecordCode;
  }

  if (orgCode !== undefined) {
    return pageData.recordList?.[orgCode];
  }

  return;
}

const SUBLIST_COLUMNS_NUM = 2;

const buildSubListMatrix = (subListData: SubListItem[] = []): SubListItem[][] => {
  const sublistMatrixColumnLength = Math.ceil((subListData.length || 0) / SUBLIST_COLUMNS_NUM);

  const sublistMatrix = subListData.reduce((matrix, item, itemIndex) => {
    const columnIndex = Math.floor(itemIndex / sublistMatrixColumnLength);
    matrix[columnIndex] = matrix[columnIndex] || [];
    matrix[columnIndex].push(item);
    return matrix;
  }, [] as SubListItem[][]);

  return sublistMatrix;
}


const InboxOrgPage: React.FC<InboxStartPageProps> = ({ inbox, groupID }) => {
  const [pageData, setPageData] = useState<PageData | undefined>();
  const [curOrgCode, setCurOrgCode] = useState<string | number | undefined>();

  const { orgData, subListMatrix } = useMemo(() => {
    const orgData = extractOrgData(pageData, curOrgCode);
    const subListMatrix = buildSubListMatrix(orgData?.sublist?.data);
    return { orgData, subListMatrix };
  }, [pageData, curOrgCode]);

  useEffect(() => {
    const apiRoute =
      inbox === 'mto' ?
      '/mto' :
      inbox === 'pap' ?
      '/PapStartPage' :
      undefined;

    if (apiRoute) {
      axios.get(apiRoute).then((res: AxiosResponseExt) => {
        if (res.error) {
          notification.error({
            text: res.error,
          });
          return;
        }
        
        const apiData = res.data;
        let pageData: PageData = apiData;
        if (inbox === 'mto') {
          pageData = {
            record: {
              logo: {
                image: process.env.REACT_APP_TYPE === 'irk' ? mtoImage : mtoCSDPImage,
                link: 'https://www.rapart.aero/',
              },
              title: 'ООО «РАпарт Сервисез»',
              about: apiData.rapartServices.about,
              sublist: {
                title: apiData.rapartServices.title,
                data: apiData.rapartServices.data,
              },
              contacts: apiData.contacts,
            }
          };
        }

        setPageData(pageData);
      });
    }
  }, [inbox]);

  if (!orgData || !pageData) return null;

  return (
    <>
      <div className={styles.titleWrapper}>
        <Title title={pageData.pageTitle || groupID}/>
        <div className={styles.toolWrapper}>
          {pageData.tools?.map(tool => {
            if (tool.action === 'choice') {
              const items = tool.choiceItems?.map(
                item => ({
                  key: item.code,
                  label: item.title,
                  onClick: () => setCurOrgCode(item.code)
                })
              );
              return (
                <Dropdown placement='bottom'
                  menu={{ items }}
                  overlayClassName={styles.dropdownOverlay}
                >
                  <Button className={stylesEntityList.button}>
                    {getInboxGroupToolIcon(tool.icon)}
                    {tool.label}
                  </Button>
                </Dropdown>
              );
            }

            return null;
          })}
        </div>
      </div>
      <Row className={[inboxGroupListStyles.list, 'main-content'].join(' ')}>
        <div className={styles.wrapPage}>
          <div className={styles.wrapAboutOrg}>
            {orgData.logo ? (
              <a href={orgData.logo.link} target='_blank'>
                <img className={styles.img} src={orgData.logo.image} />
              </a>
            ) : null}
            <div className={styles.aboutOrgText}>
              {orgData.title}
              <span className={styles.aboutOrgSubTitle}> - {orgData.about}</span>
            </div>
          </div>
          <div className={styles.wrapperList}>
            <div className={styles.title}>{orgData.sublist?.title}</div>
            <div className={styles.wrapperListColumns}>
            {subListMatrix.map(column => {
              return (
                <ul className={styles.list}>
                  {column.map((item, i) => {
                    const content = (
                      <>
                        <div className={styles.itemTitle}>{item.title}</div>
                        <div className={styles.itemSubTitle}>{item.subtitle}</div>
                      </>
                    );
                    return (
                      <li key={i} className={styles.elementList}>
                        {item.url ?
                          <a href={item.url} target='_blank'>{content}</a> :
                          <div>{content}</div>}
                      </li>
                    );
                  })}
                </ul>
              );
            })}
            </div>
          </div>
          <div className={styles.contacts}>{orgData.contacts && <SectionContact block={orgData.contacts} />}</div>
        </div>
      </Row>
    </>
  );
};

export default InboxOrgPage;
