import React, { useState } from 'react';
import cn from 'classnames';
import styles from 'components/TableView/index.module.css';
import { Row } from 'antd';
import TableViewToolButton from 'components/TableView/components/TableViewToolButton';
import { IButtonsMap } from 'utils/types';
import { isArray } from 'lodash';

interface ITableViewToolsProps { 
  isShortView: any,
  tableViewToolsButtonProps: any,
  initTools?: IButtonsMap | any[],
  readonly: any 
}

export const TableViewTools = ({ isShortView, tableViewToolsButtonProps, initTools, readonly }: ITableViewToolsProps) => {
  // только чтобы запустить ререндер
  const [refresh, setRefresh] = useState<number>(0);

  const handleChangeVisibleButton = (addTools: any[] = [], removeTools: any[] = []) => {
    if (!initTools) return;

    if (isArray(initTools))
    initTools.forEach((tool: any) => {
      if (!tool.id) return;

      const add = addTools.includes(tool.id);
      const remove = removeTools.includes(tool.id);

      if (add && !remove) tool.hidden = false;
      if (remove && !add) tool.hidden = true;
    });

    setRefresh((prevState) => prevState + 1);
  };
  // Конфигурация кнопок переходит на новую, более универсальную схему. Сейчас реализована поддержка как старых кнопок, так и новых
  // Старые кнопки приходя массивом, новые - мапой
  if (isArray(initTools)) {
    if (isShortView)
    return (
      <div className={cn(styles.wrapper, styles.wrapperForCardTable)}>
        <Row className={styles.wrapperButton}>
          {initTools?.map((tool: any) => (
            <TableViewToolButton
              {...{
                ...tableViewToolsButtonProps,
                key: String(tool.label + tool.action + tool.icon),
                title: tool.label,
                icon: tool.icon,
                name: tool.action,
                creationType: tool.creationType || tool.type,
                fields: tool.fields,
                needUpdate: tool.update,
                hidden: tool.hidden,
                addTools: tool.addTools,
                removeTools: tool.removeTools,
                handleChangeVisibleButton,
                isOldButton: true
              }}
              readonly={readonly}
            />
          )) || null}
        </Row>
      </div>
    );

    return (
      <div className={styles.wrapper}>
        <Row className={styles.wrapperButton}>
          {initTools?.map((tool: any) => {
            if (!tool) return null;

            const buttons: [string, any][] = Object.entries(tool);

            if (!buttons.length) return null;

            return buttons.map(([name, button]) => {
              return (
                <TableViewToolButton
                  {...{
                    key: String(button.title + button.icon + name),
                    ...tableViewToolsButtonProps,
                    ...button,
                    name,
                    handleChangeVisibleButton,
                    isOldButton: true
                  }}
                />
              );
            });
          }) || null}
        </Row>
      </div>
    );

  } else {
    return (
      <div className={cn(styles.wrapper, isShortView ? styles.wrapperForCardTable : null)}>
        <Row className={styles.wrapperButton}>
          {initTools && Object.keys(initTools)?.map((toolKey) => (
            <TableViewToolButton
              {...{
                ...tableViewToolsButtonProps,
                key: String(initTools[toolKey].label + initTools[toolKey].type + initTools[toolKey].icon),
                title: initTools[toolKey].label,
                icon: initTools[toolKey].icon,
                controller: initTools[toolKey].controller,
                type: initTools[toolKey].type,
                name: initTools[toolKey].action,
                toolName: toolKey,
                isOldButton: false
              }}
              readonly={readonly}
            />
          )) || null}
        </Row>
      </div>
    );
  }
};
