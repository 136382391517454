import cn from 'classnames';
import styles from 'components/TableGrid/index.module.css';
import React, { FC } from 'react';
import { Spin } from 'antd';
import {useTranslation} from 'react-i18next';

type TablePreLoaderPropsType = {
  small?: boolean;
};

export const TablePreLoader: FC<TablePreLoaderPropsType> = ({ small = true }) => {
  const {t} = useTranslation();
  
  return (
  <div className={cn(styles.empty, { [styles.empty__small]: small })}>
    <h2 className={cn(styles.emptyTitle, { [styles.emptyTitleForCardTable]: small })}>{t("loading")}</h2>
    <div className={styles.spinBox}>
      <Spin spinning={true} />
    </div>
    {!small && (
      <>
        <div className={styles.row} />
        <div className={styles.row} />
      </>
    )}
  </div>
  )
};
