import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';
import ServerAPI from 'integration/ServerAPI';
import ServerError from 'integration/ServerError';
import { AxiosResponseNull } from 'interfaces/AxiosResponseExt';
import ControlFactory from 'utils/ControlFactory';
import { isNewID } from 'utils/ObjectUtils';
import { JsObjectNested } from 'interfaces/Object';

export interface CreateLayoutFactoryParams {
  layoutConfig?: any;
  layoutParams?: JsObjectNested;
  readOnly?: boolean;
}

type GetDetailsTabLayout = () => Promise<any>;

type CreateLayoutFactory = (
  params?: CreateLayoutFactoryParams
) => Promise<ControlFactory | undefined>;

type SetNewLayoutFactory = CreateLayoutFactory;

type UseLayoutFactory = (args: {
  className: string;
  objectID?: string;
  id?: string; // alias for objectID
  parentId?: string;
  layoutParentId?: string; // alias for parentId - в этом хуке parentId нужен только для getDetailsTabLayout
  parentClassName?: string;
  customLayout?: string;
  inboxName?: string;
}) => {
  layoutFactory?: ControlFactory;
  setLayoutFactory: React.Dispatch<React.SetStateAction<ControlFactory | undefined>>;
  getDetailsTabLayout: GetDetailsTabLayout;
  createLayoutFactory: CreateLayoutFactory;
  setNewLayoutFactory: SetNewLayoutFactory;
  purgeLayoutFactory: () => void;
};

export const useLayoutFactory: UseLayoutFactory = (args) => {
  const className = args.className;
  const _id = args.objectID || args.id;
  const id = isNewID(_id) ? undefined : _id;
  const layoutParentId = args.layoutParentId || args.parentId || undefined;
  const parentClassName = args.parentClassName || undefined;
  const customLayout = args.customLayout || undefined;
  const inboxName = args.inboxName || undefined;

  const dispatch = useDispatch();
  const width = useWindowWidth();
  const [layoutFactory, setLayoutFactory] = useState<ControlFactory>();

  layoutFactory?.updateDispatch(dispatch);

  const getDetailsTabLayout: GetDetailsTabLayout = async () => {
    const response: AxiosResponseNull = await ServerAPI.getDetailsTabLayout({
      className,
      id,
      parentId: layoutParentId,
      parentClassName,
      customLayout,
      inboxName
    }).catch((serverError: ServerError) => serverError.notify(width));

    return response?.data;
  };

  const createLayoutFactory: CreateLayoutFactory = async ({
    layoutConfig,
    layoutParams,
    readOnly,
  } = {}) => {
    const config: any = layoutConfig || (await getDetailsTabLayout());

    if (!config) return;

    const newFactory = new ControlFactory(dispatch);
    await newFactory.setConfig(config);
    newFactory.setClassType(className);

    if (layoutParams) newFactory.modifyComponents(layoutParams);

    if (readOnly) newFactory.disableComponents();

    return newFactory;
  };

  const setNewLayoutFactory: SetNewLayoutFactory = async (params) => {
    const newFactory: ControlFactory | undefined = await createLayoutFactory(params);
    setLayoutFactory(newFactory);
    return newFactory;
  };

  const purgeLayoutFactory = useCallback(() => setLayoutFactory(undefined), []);

  return {
    layoutFactory,
    setLayoutFactory,
    getDetailsTabLayout,
    createLayoutFactory,
    setNewLayoutFactory,
    purgeLayoutFactory,
  };
};

export default useLayoutFactory;
