import React, { useContext, useEffect, useState } from 'react';
import KpiChart from './KpiChart';
import styles from './Kpi.module.css';
import tableStyles from '../reports/TableRequestsForThePeriod.module.css';
import { Collapse, Form, Row, Spin, Table, Button } from 'antd';
import Title from '../../components/layout/content/title/Title';
import stylesButtons from '../entityEditor/Buttons/EntityEditorButtons.module.css';
import entityEditorDetalisStyle from '../entityEditor/Tabs/EntityEditorDetails.module.css';
import AccordionIcon from '../../components/icons/AccordionIcon';
import { getDateValueForPeriod, indicatorKpi, indicatorKpiRA, layout, periodText } from './utils';
import ControlFactory from '../../utils/ControlFactory';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../store/actionTypes';
import EntityEditorContext from '../entityEditor/EntityEditorContext/EntityEditorContext';
import { cloneDeep, isArray, uniqueId } from 'lodash';
import axios from 'axios';
import { AxiosResponseExt } from '../../interfaces/AxiosResponseExt';
import moment from 'moment';
import GlobalLoaderContext from 'components/layout/loader/GlobalLoaderContext';
import { handleTitle } from 'pages/reports/TableRequestsForThePeriod';
import FormatUtils from 'utils/FormatUtils';
import FileSaver from 'file-saver';
import IntegralIndicators from 'pages/reports/IntegralIndicators';
import reportStyles from '../reports/reports.module.css';
import { useWindowWidth } from '@react-hook/window-size';
import pdfMake from 'pdfmake';
import html2canvas from 'html2canvas';
import notification from 'components/messages/notification';
import { useTranslation } from 'react-i18next';

let valuesChangeTimerId: any;

export enum KPIType {
  PERCENT = 'persent',
  MINUTES = 'minutes',
}

export const renderByKpiType = (kpiType: KPIType, value: string, withUnits = false) => {
  if (kpiType === KPIType.MINUTES) {
    if (withUnits) {
      const values: any = value.split(':');
      if (values.length === 2) {
        return `${values[0]} ч ${values[1]} мин`;
      }
    }
    return `${value}`;
  }
  return `${FormatUtils.formatNumberNN(value)} ${withUnits ? '%' : ''}`;
};

export interface KpiData {
  data: any[];
  kpiType: KPIType;
  diffDays: number;
}

interface KpiAverageData {
  average: string;
  deviationAverage: string;
}

interface IKpiProps {
  isRA?: boolean;
}

const Kpi: React.FC<IKpiProps> = ({ isRA }) => {
  const { Panel } = Collapse;

  const { Column, ColumnGroup } = Table;

  const dispatch = useDispatch();

  const [form] = useForm();

  const globalLoader = useContext(GlobalLoaderContext);

  const [kpiType, setKpiType] = useState<KPIType>(KPIType.PERCENT);
  const [data, setData] = useState<KpiData>();
  const [diffDays, setDiffDays] = useState<number>(0);
  const [factory, setFactory] = useState<ControlFactory>();
  const width = useWindowWidth();
  const [dataIntegralIndicators, setDataIntegralIndicators] = useState<any>([]);
  const [exportPdfEnabled, setExportPdfEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [averageData, setAverageData] = useState<KpiAverageData>({
    average: '',
    deviationAverage: '',
  });

  const { t } = useTranslation();

  useEffect(() => {
    resetValues();
    const factoryInstance: ControlFactory = new ControlFactory(dispatch);
    factoryInstance.setConfig(cloneDeep(layout(t, isRA)));
    factoryInstance._fetchChoiceListData().then(() => {
      setFactory(factoryInstance);
    });
    dispatch({
      type: ActionType.UPDATE_CHOICE_LISTS,
      payload: {
        indicatorKpi: isRA ? indicatorKpiRA : indicatorKpi(t),
        periodText: periodText(t),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetValues = () => {
    setData(undefined);
    form.resetFields();
    form.setFieldsValue({
      indicatorKpi: 'percentIndicator',
      targetKpi: '85',
      periodText: 'currentMonth',
      periodData: [moment().startOf('month'), moment()],
      aircraftType: '',
      ataAircraftType: '',
      ownerKpi: '',
      priority: '1',
      organizationList: '',
      department: 2,
    });
  };

  const handleReadOnly = (periodType: string) => {
    const readOnly: boolean = periodType !== 'arbitraryPeriod';
    const newLayout: any = cloneDeep(layout(t, isRA));
    newLayout.panels.forEach((panel: any) => {
      panel.componentsRows.forEach((cr: any) => {
        cr.components.forEach((c: any) => {
          if (c.propName === 'periodData') {
            c.readonly = readOnly;
            return;
          }
        });
      });
    });
    const factoryInstance: ControlFactory = new ControlFactory(dispatch);
    factoryInstance.setConfig(cloneDeep(newLayout));
    setFactory(factoryInstance);
  };

  const updateFormValues = (newChangedValues: any) => {
    onFormChange(newChangedValues, form.getFieldsValue(true));
    form.setFieldsValue(newChangedValues);
  };

  const onFormChange = (changedValues: any, allValues: any) => {
    if (factory && factory.isFetchValue(changedValues)) {
      clearTimeout(valuesChangeTimerId);
      valuesChangeTimerId = setTimeout(() => {
        factory.handleComponentChange(changedValues, form);
      }, 300);
    }
    if (changedValues.indicatorKpi === 'timeIndicator') {
      updateFormValues({
        targetKpi: '24:00',
        aircraftType: '66af2855-1380-438f-b72b-a5d6e7592d14',
        priority: '1',
      });
      return;
    } else if (changedValues.indicatorKpi === 'percentIndicator') {
      updateFormValues({ targetKpi: '85' });
      return;
    }
    if (allValues.indicatorKpi === 'timeIndicator') {
      if (changedValues.aircraftType === '66af2855-1380-438f-b72b-a5d6e7592d14') {
        if (allValues.priority === '1') {
          updateFormValues({ targetKpi: '24:00' });
          return;
        } else if (allValues.priority === '2') {
          updateFormValues({ targetKpi: '72:00' });
          return;
        } else if (allValues.priority === '3') {
          updateFormValues({ targetKpi: '720:00' });
          return;
        }
      } else if (changedValues.aircraftType === 'b513a833-8420-4244-8d6c-d8b6799be7a6') {
        if (allValues.priority === '1') {
          updateFormValues({ targetKpi: '4:00' });
          return;
        } else if (allValues.priority === '72:00') {
          updateFormValues({ targetKpi: '72:00' });
          return;
        } else if (allValues.priority === '3') {
          updateFormValues({ targetKpi: '720:00' });
          return;
        }
      } else {
        if (changedValues.priority === '1') {
          if (allValues.aircraftType === '66af2855-1380-438f-b72b-a5d6e7592d14') {
            updateFormValues({ targetKpi: '24:00' });
            return;
          } else if (allValues.aircraftType === 'b513a833-8420-4244-8d6c-d8b6799be7a6') {
            updateFormValues({ targetKpi: '4:00' });
            return;
          }
        } else if (changedValues.priority === '2') {
          updateFormValues({ targetKpi: '72:00' });
          return;
        } else if (changedValues.priority === '3') {
          updateFormValues({ targetKpi: '720:00' });
          return;
        }
      }
    }
    if (changedValues.periodText) {
      handleReadOnly(changedValues.periodText);
      if (changedValues.periodText !== 'arbitraryPeriod') {
        const periodData: moment.Moment[] = getDateValueForPeriod(changedValues.periodText, t);
        form.setFieldsValue({ periodData });
      } else {
        form.setFieldsValue({ periodData: [] });
      }
    }
  };

  const convertFormToRequest = (values: any) => {
    const newValues: any = cloneDeep(values);
    const {
      indicatorKpi,
      targetKpi,
      periodData,
      priority,
      ataAircraftType,
      aircraftType,
      aircraftModel,
      organizationList,
      department_,
      productType,
      requestType,
    } = newValues;
    return {
      indicatorKpi,
      target: targetKpi,
      start: periodData[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end: periodData[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      department: process.env.REACT_APP_TYPE === 'irk' ? 2 : department_,
      priority,
      ATA: ataAircraftType,
      aircraftType,
      productType,
      requestType,
      aircraftModel: aircraftModel ? [aircraftModel] : undefined,
      сlientOrganization: organizationList,
    };
  };

  const generateReport = (values: any) => {
    globalLoader.setLoading(true);
    const newValues: any = convertFormToRequest(values);
    const formData = new FormData();
    Object.keys(newValues).forEach((key: string) => {
      if (typeof newValues[key] !== 'undefined') {
        formData.append(
          key,
          isArray(newValues[key]) ? JSON.stringify(newValues[key]) : newValues[key]
        );
      }
    });
    axios
      .post(process.env.REACT_APP_TYPE === 'irk' ? '/KPIoscReport' : isRA ? '/KPIRAReport' : '/KPIReport', formData)
      .then((response: AxiosResponseExt) => {
        if (!response.error) {
          const newAverageData: any = response.data.averageData;
          const newIntData: any[] = response.data.integralData || [];
          const newData: any[] = response.data.data || [];
          const newDiffDays: number = FormatUtils.getDiffDays(
            newData.length > 2 ? newData[2].time_period : newData[0].time_period
          );
          const newType: KPIType =
            newValues.indicatorKpi === 'timeIndicator' ? KPIType.MINUTES : KPIType.PERCENT;
          setDiffDays(newDiffDays);
          setData({
            data: newData,
            kpiType: newType,
            diffDays: newDiffDays,
          });
          setKpiType(newType);
          setDataIntegralIndicators(newIntData);
          setAverageData(newAverageData);
        }
      })
      .finally(() => {
        globalLoader.setLoading(false);
      });
  };

  const exportReport = () => {
    globalLoader.setLoading(true);
    const newValues: any = convertFormToRequest(form.getFieldsValue(true));
    const formData = new FormData();
    Object.keys(newValues).forEach((key: string) => {
      if (typeof newValues[key] !== 'undefined') {
        formData.append(
          key,
          isArray(newValues[key]) ? JSON.stringify(newValues[key]) : newValues[key]
        );
      }
    });
    axios
      .post(process.env.REACT_APP_TYPE === 'irk' ? '/KPIoscReport/export' : isRA ? '/KPIRAReport/export' : '/KPIReport/export', formData)
      .then((response: AxiosResponseExt) => {
        if (response.error) {
          notification.error({
            text: response.error,
            width,
          });
        } else {
          const element = document.createElement('a');
          element.setAttribute('href', `${response.data.file}`);
          element.setAttribute('target', '_blank');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          notification.success({
            text: response.data.message,
            width,
          });
        }
      })
      .finally(() => {
        globalLoader.setLoading(false);
      });
  };

  const dates = form.getFieldValue('periodData');
  const newDates =
    dates &&
    dates.map((date: any) => {
      const newDate: any = new Date(date);
      const options: any = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      return newDate.toLocaleDateString('ru', options);
    });
  const wrapDate: any = document.querySelector('.wrapDate');

  const getPdf = () => {
    setExportPdfEnabled(true);
    setLoading(true);
    const integralIndicators: HTMLElement | null = document.querySelector('.integralIndicators');
    const chart: HTMLElement | null = document.querySelector('.chartKpi');
    const formKpi: HTMLElement | null = document.querySelector('.formKpi');
    wrapDate.textContent = `Период с ${newDates[0]} по ${newDates[1]}`;
    if (chart !== null && integralIndicators !== null && wrapDate !== null && formKpi !== null) {
      html2canvas(integralIndicators)
        .then((integralIndicatorsCanvas: HTMLCanvasElement) => {
          setExportPdfEnabled(false);
          html2canvas(chart)
            .then((chartCanvas: HTMLCanvasElement) => {
              html2canvas(wrapDate)
                .then((dateCanvas) => {
                  html2canvas(formKpi)
                    .then((formCanvas) => {
                      const imgForm = {
                        image: formCanvas.toDataURL(),
                        width: 800,
                        style: {
                          alignment: 'center',
                          marginBottom: 20,
                        },
                      };

                      const imgDate = {
                        image: dateCanvas.toDataURL(),
                        width: 200,
                        style: {
                          alignment: 'center',
                          marginBottom: 20,
                        },
                      };
                      const imgIntegralIndicators = {
                        image: integralIndicatorsCanvas.toDataURL(),
                        width: 800,
                        style: {
                          alignment: 'center',
                        },
                      };
                      const chart = {
                        image: chartCanvas.toDataURL(),
                        width: 800,
                        style: {
                          alignment: 'center',
                        },
                      };
                      const documentDefinition = {
                        content: [imgDate, imgForm, chart, imgIntegralIndicators],
                        defaultStyle: {
                          font: 'NimbusSans',
                        },
                        // pageSize: "A4",
                        pageOrientation: 'landscape',
                        pageMargins: [40, 60, 40, 60],
                      };
                      // @ts-ignore
                      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
                      const dates = form.getFieldValue('periodData');
                      const newDates =
                        dates &&
                        dates.map((date: any) => {
                          const newDate: any = new Date(date);
                          const options: any = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          };
                          return newDate.toLocaleDateString('ru', options);
                        });
                      pdfDocGenerator.download(`График KPI за период ${newDates[0]}-${newDates[1]}.pdf`);
                    })
                    .catch((err) => {
                      notification.error({
                        text: t("fileGenerationError"),
                        width,
                      });
                    });
                })
                .catch((err) => {
                  notification.error({
                    text: t("fileGenerationError"),
                    width,
                  });
                })
                .finally(() => {
                  setLoading(false);
                  setExportPdfEnabled(false);
                });
            })
            .catch((err) => {
              notification.error({
                text: t("fileGenerationError"),
                width,
              });
            });
        })
        .catch((err) => {
          notification.error({
            text: t("fileGenerationError"),
            width,
          });
        });
    }
  };

  return (
    <div className={`${styles.wrapper} page`}>
      <Spin spinning={loading}>
        <div className={'js-parentNode'}>
          <Title title={'KPI'} />
          <div className={stylesButtons.container}>
            <div
              className={stylesButtons.button}
              onClick={() => {
                form.submit();
              }}
            >
              {t("generate")} {process.env.REACT_APP_TYPE === 'csdp' && t('report')}
            </div>
            {
              process.env.REACT_APP_TYPE === 'irk' &&
              <div
                className={stylesButtons.button}
                onClick={() => {
                  resetValues();
                }}
              >
                {t("clear")}
              </div>
            }
            {data && (
              <>
                <div className={stylesButtons.button} onClick={exportReport}>
                  {t("exportingSummaryData")}
                </div>
                <Button
                  htmlType='button'
                  type={'text'}
                  disabled={exportPdfEnabled}
                  className={stylesButtons.button}
                  onClick={getPdf}
                >
                  {t("chartExport")}
                </Button>
              </>
            )}
          </div>
          <Form
            layout='vertical'
            className={styles.form}
            form={form}
            onValuesChange={onFormChange}
            onFinish={generateReport}
            scrollToFirstError={true}
          >
            {factory != null && factory.getPanels() != null ? (
              <EntityEditorContext.Provider
                value={{
                  form,
                  factory,
                  documentId: uniqueId(),
                }}
              >
                {factory.getPanels().map((panel: any, i: number) => {
                  return (
                    <Collapse
                      key={i}
                      className={entityEditorDetalisStyle.collapse}
                      ghost={true}
                      expandIcon={({ isActive }) => <AccordionIcon active={Boolean(isActive)} />}
                      defaultActiveKey={`panel_${i}`}
                    >
                      <Panel header={panel.legend} key={`panel_${i}`} className='formKpi'>
                        {panel.componentsRows &&
                          panel.componentsRows.map((row: any, j: number) => {
                            return (
                              <Row gutter={16} key={`${i}_${j}`}>
                                {row.components &&
                                  row.components.map((component: any, k: number) => {
                                    const options = {
                                      additionProps: {},
                                      component,
                                    };
                                    return (
                                      <React.Fragment key={`${i}_${j}_${k}`}>
                                        {factory && factory.create(options)}
                                      </React.Fragment>
                                    );
                                  })}
                              </Row>
                            );
                          })}
                      </Panel>
                    </Collapse>
                  );
                })}
              </EntityEditorContext.Provider>
            ) : (
              <></>
            )}
          </Form>
          <Collapse
            className={entityEditorDetalisStyle.collapse}
            ghost={true}
            expandIcon={({ isActive }) => <AccordionIcon active={Boolean(isActive)} />}
            defaultActiveKey={[1, 2, 3, 4]}
          >
            {<div className={`${styles.wrapDate} wrapDate`} />}
            <Panel key={'2'} header={t("chart")} className='chartKpi'>
              {data ? (
                <div className={styles.wrapperPie}>
                  <KpiChart
                    kpiData={data}
                    targetKpi={form.getFieldValue('targetKpi')}
                    width={width}
                    isRA={isRA}
                  />
                  <div className={styles.wrapperTitle}>
                    <div className={styles.titleContainer}>
                      <div className={styles.title}>{t('averageCalculatedValue')}:</div>
                      <div className={styles.count}>
                        {renderByKpiType(kpiType, averageData.average, true)}
                      </div>
                    </div>
                    <div className={styles.titleContainer}>
                      <div className={styles.title}>{t('deviationFromKPITarget')}:</div>
                      <div className={styles.count}>
                        {renderByKpiType(kpiType, averageData.deviationAverage, true)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.emptyKpiChart}>
                  {t('toBuildTheChartConfigureTheMainInformationBlockAndClickGenerate')}{process.env.REACT_APP_TYPE === 'csdp' && ` ${t('report')}`}"
                </div>
              )}
            </Panel>
            <Panel key={'3'} header={t("integralIndicators")} className='integralIndicators'>
              <div className={reportStyles.wrapperIntegral}>
                {dataIntegralIndicators.map((item: any, i: number) => {
                  return (
                    <IntegralIndicators
                      key={`integral_${i}`}
                      title={item.headerTitle}
                      data={item.rows}
                      columns={item.columns}
                      sum={item.headerValue}
                    />
                  );
                })}
              </div>
            </Panel>
            <Panel key={'4'} header={t("summaryData")} className={styles.panelTable}>
              <div className={tableStyles.wrap}>
                <Table
                  className={`${tableStyles.table} ${styles.tableKpi}`}
                  scroll={{ x: '100%' }}
                  dataSource={data && data.data ? data.data : []}
                  pagination={false}
                  rowKey={() => uniqueId()}
                >
                  <Column
                    title=''
                    className={tableStyles.columnMonth}
                    dataIndex='time_period'
                    render={(values: any) => {
                      if (isArray(values) && values.length > 1) {
                        return FormatUtils.formatDatesRagne(values, diffDays);
                      }
                      return '';
                    }}
                  />

                  <ColumnGroup
                    title={
                      kpiType === KPIType.MINUTES
                        ? `Время обработки ${isRA ? 'РА' : t("requests")}, чч:мм`
                        : `${t('percentageOf')} ${isRA ? 'РА' : t("requests")}${t('processedOnTimeTwo')}, %`
                    }
                    className={tableStyles.columnForBorder}
                  >
                    <Column
                      title={handleTitle(t('total'))}
                      dataIndex='all'
                      render={(value: any) => {
                        return renderByKpiType(kpiType, value);
                      }}
                    />
                    {
                      !isRA ?
                        <>
                          <Column
                            title={handleTitle('AOG')}
                            dataIndex='aog'
                            render={(value: any) => renderByKpiType(kpiType, value)}
                          />
                          <Column
                            title={handleTitle('Critical')}
                            dataIndex='critical'
                            render={(value: any) => renderByKpiType(kpiType, value)}
                          />
                          {
                            process.env.REACT_APP_TYPE !== 'irk' ?
                              <Column
                                title={handleTitle('Work Stoppage')}
                                dataIndex='workStoppage'
                                render={(value: any) => renderByKpiType(kpiType, value)}
                              />
                              : <></>
                          }
                          <Column
                            title={handleTitle('Routine')}
                            className={tableStyles.columnForBorder}
                            dataIndex='routine'
                            render={(value: any) => renderByKpiType(kpiType, value)}
                          />
                        </> : <></>
                    }
                  </ColumnGroup>
                  {
                    !isRA ?
                      <>
                        <Column
                          title={`${t('targetKPI')}, ${kpiType === KPIType.MINUTES ? t('timeFormat') : '%'}`}
                          dataIndex='target'
                          className={tableStyles.columnForBorder}
                          render={(value: any) => renderByKpiType(kpiType, value)}
                        />
                        <ColumnGroup
                          title={`${t('deviationFromTarget')}, ${kpiType === KPIType.MINUTES ? t('timeFormat') : '%'
                            }`}
                          className={tableStyles.columnForBorder}
                        >
                          <Column
                            title={handleTitle(t('total'))}
                            dataIndex='deviationAll'
                            render={(value: any) => renderByKpiType(kpiType, value)}
                          />
                          <Column
                            title={handleTitle('AOG')}
                            dataIndex='deviationAOG'
                            render={(value: any) => renderByKpiType(kpiType, value)}
                          />
                          <Column
                            title={handleTitle('Critical')}
                            dataIndex='deviationCritical'
                            render={(value: any) => renderByKpiType(kpiType, value)}
                          />
                          {
                            process.env.REACT_APP_TYPE !== 'irk' ?
                              <Column
                                title={handleTitle('Work Stoppage')}
                                dataIndex='deviationWorkStoppage'
                                render={(value: any) => renderByKpiType(kpiType, value)}
                              />
                              : <></>
                          }
                          <Column
                            title={handleTitle('Routine')}
                            className={tableStyles.columnForBorder}
                            dataIndex='deviationRoutine'
                            render={(value: any) => renderByKpiType(kpiType, value)}
                          />
                        </ColumnGroup>
                      </> : <></>
                  }
                </Table>
              </div>
            </Panel>
          </Collapse>
        </div>
      </Spin>
    </div>
  );
};

export default Kpi;
