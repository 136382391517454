import { ActionType, Action } from '../actionTypes';

const initialState = {
  typeUser: false,
  userData: {},
  isLocaleInited: false
};

const typeUser = (state: any = initialState, action: Action<ActionType, any>) => {
  switch (action.type) {
    case ActionType.TYPE_USER:
      return { ...action.payload };
    case ActionType.UPDATE_USER_DATA: {
      return {
        ...state,
        ...{
          userData: action.payload,
        },
      };
    }
    case ActionType.SET_IS_LOCALE_INITED:
      return {
        ...state,
        isLocaleInited: action.payload
      }
    default:
      return state;
  }
};

export default typeUser;
