import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Form, Modal, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import ControlFactory from 'utils/ControlFactory';
import EntityEditorContext from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import CardTable from 'components/controls/CardTable';
import CardLayout from 'components/CardLayout';
import FilesList from 'components/controls/FilesList/FilesList';
import notification from 'components/messages/notification';

import styles from 'components/TableGrid/index.module.css';

import {
  useLayoutFactory,
  useModalForm,
  useObjectCrud,
  useRefreshComponent,
  useFormHandler,
  useEditorChanged,
  useRefStore,
} from 'hooks';

import ServerAPI from 'integration/ServerAPI';
import ServerError from 'integration/ServerError';
import FormHandler from 'controllers/FormHandler';

import { EntityEditorRouteParams, EntityListRouteParams } from 'router/Routes';
import { getExt } from 'components/controls/FilesList/FileItem';
import { formatFilenameFromResponse } from 'utils/FormatUtils';
import { generateId } from 'utils/MiscUtils';
import { AppState } from 'store/reducers';
import { ModalType } from 'store/reducers/modal';
import { AxiosResponseNull } from 'interfaces/AxiosResponseExt';
import { ObjectData } from 'interfaces/Object';
import { FormObjectData } from 'interfaces/Form';
import { ControlType } from 'interfaces/BaseComponentInterface';
import { getDocumentId, isNewObjectData } from 'utils/ObjectUtils';
import { RefreshID } from 'enums/refresh';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

export type ProcessClick = (
  event: React.MouseEvent<HTMLElement, MouseEvent>
) => void | Promise<void>;

export enum ModalLabels {
  CANCEL = 'Отмена',
  ATTACH_TITLE = 'Прикрепить объекты',
  ATTACH_OK = 'Выбрать',
  DETAILS_OK = 'Сохранить',
  IMPORT_FILES_TITLE = 'Импорт данных',
  IMPORT_FILES_OK = 'Импортировать',
}

const getModalLabels = (modalType: ModalType | null, modalFactory?: ControlFactory) => {
  let title,
    okText,
    cancelText = i18n.t('cancel');

  switch (modalType) {
    case ModalType.ATTACH:
      title = ModalLabels.ATTACH_TITLE;
      okText = ModalLabels.ATTACH_OK;
      break;
    case ModalType.CREATE:
    case ModalType.EDIT:
    case ModalType.COPY:
    case ModalType.READONLY:
      title = modalFactory?.getConfig()?.params?.Title || '';
      okText = i18n.t('save');
      break;
    case ModalType.IMPORT_FILES:
      title = ModalLabels.IMPORT_FILES_TITLE;
      okText = ModalLabels.IMPORT_FILES_OK;
      break;
  }

  return { title, okText, cancelText };
};

const getModalState = (state: AppState) => ({
  parentDetails: state.modal.parentDetails || state.editorDetails,
  parentForm: state.editor.form,
  visible: state.modal.visible,
  modalDetails: state.modal.modalDetails,
  modalType: state.modal.type,
  parentId: state.modal.parentId,
  layoutParentId: state.modal.layoutParentId,
  parentClassName: state.modal.parentClassName,
  id: state.modal.objectId || state.modal.modalDetails?.id || '',
  className: state.modal.objectClassName || state.modal.modalDetails?.classType || '',
  toolName: state.modal.toolName,
  attachParams: state.modal.attachParams,
  refreshIDs: state.modal.refreshIDs,
  customLayout: state.modal.customLayout,
  layoutParams: state.modal.layoutParams,
  cardForm: state.modal.cardForm,
  cardFormValues: state.modal.cardFormValues,
  selectedRow: state.selectedRows,
  massReplaceParams: state.modal.massReplaceParams,
});

const ModalForm: React.FC<{}> = () => {
  const routeMatch = useRouteMatch<EntityEditorRouteParams & EntityListRouteParams>();

  const {t} = useTranslation();
  const {
    parentDetails,
    parentForm,
    visible,
    modalDetails,
    modalType,
    parentId,
    layoutParentId = routeMatch.params.objectID,
    parentClassName,
    id,
    className,
    toolName,
    attachParams,
    refreshIDs,
    customLayout,
    layoutParams,
    selectedRow,
    cardForm,
    cardFormValues,
    massReplaceParams,
  } = useSelector(getModalState, shallowEqual);

  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [importFile, setImportFile] = useState<File>();
  const attachTableIdRef = useRef<string>(generateId('modal_attach_table'));
  const attachTableId = attachTableIdRef.current;

  const { toggleProcessKeyWrapper } = useRefStore();

  const {
    layoutFactory: modalFactory,
    setLayoutFactory: setModalFactory,
    createLayoutFactory: createModalFactory,
    purgeLayoutFactory: purgeModalFactory,
  } = useLayoutFactory({ id, className, customLayout, layoutParentId, parentClassName });

  // true если модалка с карточкой объекта
  const isCardModal: boolean = Boolean(
    modalType &&
      [
        ModalType.CREATE,
        ModalType.EDIT,
        ModalType.READONLY,
        ModalType.COPY,
        ModalType.MASS_REPLACE,
      ].includes(modalType)
  );

  // конечная видимость модалки, дополненная для улучшения UX
  const modalVisible: boolean = Boolean(
    visible && modalType && (!isCardModal || modalFactory || loading)
  );

  // условие на скрытие футера
  const hideTools: boolean = modalType === ModalType.READONLY || loading;

  // высота модалки
  // фиксируется пока не загрузился лэйаут карточки
  const modalHeight: string | undefined = isCardModal && !modalFactory ? '35vh' : undefined;

  // css properties тела модалки
  const modalBodyStyle = useMemo(
    () => (modalHeight ? { height: modalHeight } : undefined),
    [modalHeight]
  );

  const { setFormData, validateFields } = useFormHandler(form);

  const { preFillNewObject, getObjectInfo, createObject, updateObject, handleValuesChange } =
    useObjectCrud({
      form,
      id,
      className,
      parentId,
      parentClassName,
      toolName,
      setLoading,
      setFactory: setModalFactory,
    });

  const { closeModalForm, updateModalForm } = useModalForm();
  const { refreshComponent } = useRefreshComponent();
  const { updateEditorChanged } = useEditorChanged({ form: cardForm });

  const { title, okText, cancelText } = getModalLabels(modalType, modalFactory);

  const getData = async () => {
    setLoading(true);

    let newFactory: ControlFactory | undefined;
    let objectData: ObjectData | undefined;

    switch (modalType) {
      case ModalType.CREATE: {
        newFactory = await createModalFactory({ layoutParams });
        const data = await preFillNewObject(parentDetails, parentForm);
        objectData = data.objectData;
        break;
      }
      case ModalType.READONLY:
        newFactory = await createModalFactory({ layoutParams, readOnly: true });
        const data = await getObjectInfo(modalDetails);
        objectData = data.objectData;
        break;
      case ModalType.COPY: {
        newFactory = await createModalFactory({ layoutParams });
        const data = await getObjectInfo(modalDetails);
        objectData = data.objectData;
        break;
      }
      case ModalType.EDIT: {
        newFactory = await createModalFactory({ layoutParams });
        const data = await getObjectInfo(modalDetails);
        objectData = data.objectData;
        break;
      }
      case ModalType.MASS_REPLACE: {
        if (!massReplaceParams) return;

        const params: any = FormHandler.normalizeFormData(
          {
            [massReplaceParams.occurrencesField]: selectedRow[massReplaceParams.tableId],
          },
          true
        );

        const createObjectFetch: AxiosResponseNull = await ServerAPI.createObject({
          toolName,
          object: {
            classType: className,
            isDirty: false,
            params,
          },
        }).catch((serverError: ServerError) => serverError.notify());

        const createdObjectData: ObjectData | undefined = createObjectFetch?.data?.valueObject;
        const createdObjectId = createdObjectData?.id;
        const createdObjectClassName = createdObjectData?.classType;

        if (!createdObjectClassName) return;

        const getObjectLayoutFetch: AxiosResponseNull = await ServerAPI.getDetailsTabLayout({
          className: createdObjectClassName,
          id: createdObjectId,
        }).catch((serverError: ServerError) => serverError.notify());

        const layoutConfig = getObjectLayoutFetch?.data;

        if (!layoutConfig) return;

        const getObjectInfoFetch: AxiosResponseNull = await ServerAPI.getObjectInfoById({
          property: {
            className: createdObjectClassName,
            objectID: createdObjectId,
          },
        }).catch((serverError: ServerError) => serverError.notify());

        objectData = getObjectInfoFetch?.data;

        newFactory = await createModalFactory({ layoutConfig, layoutParams });

        break;
      }
    }

    if (objectData) updateModalForm({ modalDetails: objectData });

    if (newFactory) {
      setModalFactory(newFactory);
      await newFactory._fetchChoiceListDataWithParams(form, objectData?.params);
    }

    setLoading(false);
  };

  // toggleProcessKeyWrapper - для блокировки повторного нажатия
  const modalOnOk: ProcessClick = toggleProcessKeyWrapper(
    'blockActions',
    async (event) => {
      switch (modalType) {
        case ModalType.CREATE: {
          if (!(await validateFields())) return;
          await createObject(modalDetails);
          if (parentDetails?.params?.VersionSeriesId) {
            refreshComponent(RefreshID.EDITOR_DETAILS_DATA);
          } 
          break;
        }
        case ModalType.COPY: {
          if (!(await validateFields())) return;
          await createObject(modalDetails);
          if (parentDetails?.params?.VersionSeriesId) {
            refreshComponent(RefreshID.EDITOR_DETAILS_DATA);
          } 
          break;
        }
        case ModalType.EDIT: {
          if (!(await validateFields())) return;
          await updateObject(modalDetails);
          break;
        }
        case ModalType.ATTACH: {
          const tableArrayProp = attachParams?.fillCriteriaFrom;
          if (tableArrayProp && cardForm) {
            const stableIdx = cardForm.getFieldValue(tableArrayProp) || [];
            const finalRows = [...stableIdx];

            for (const id of selectedRow[attachTableId]) {
              if (!finalRows.includes(id)) finalRows.push(id);
            }

            cardForm.setFieldsValue({ [tableArrayProp]: finalRows });

            updateEditorChanged(true);
          }
          break;
        }
        case ModalType.MASS_REPLACE: {
          if (!(await validateFields()) || !massReplaceParams) return;
          await updateObject(modalDetails);
          break;
        }
        case ModalType.IMPORT_FILES: {
          if (!importFile || !className) return;

          const response: AxiosResponseNull = await ServerAPI.importObjects(
            {
              file: importFile,
              className,
              contentList: {
                classType: 'Content',
                params: {
                  fileName: `${className}.xls`,
                  mimeType: importFile.type,
                },
                className,
              },
            },
            {
              responseType: 'blob',
              validateStatus: (status: number) => status >= 200 && status <= 500,
            }
          ).catch((err: ServerError) => {
            err.notify();
            return err.response;
          });

          if (response?.status === 200) {
            notification.success(t('imported'));
          } else if (response?.status === 400) {
            const disposition: string | null = response.headers['content-disposition'];
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', formatFilenameFromResponse(disposition));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            notification.error(t('correctMistakes'));
            return;
          } else {
            notification.error(t('formatDoesNotMatch'));
            return;
          }

          break;
        }
      }

      if (cardForm && cardFormValues) cardForm.setFieldsValue(cardFormValues);

      if (refreshIDs?.length) refreshComponent(refreshIDs);

      closeModalForm();
    },
    { rollbackDelay: 1000 }
  );

  const modalOnCancel: ProcessClick = (event) => closeModalForm();

  const onValuesChange = (changedValues: FormObjectData) =>
    handleValuesChange(changedValues, modalDetails, modalFactory);

  const addImportFile = (file: File) => {
    if (!file) return;
    if (['xls', 'xlsx'].includes(getExt(file.name))) {
      setImportFile(file);
    } else {
      setImportFile(undefined);
      notification.error('Формат не соответствует шаблону');
    }
  };

  // на анмаунте очищаем стейт в редуксе
  useEffect(() => () => closeModalForm(), [closeModalForm]);

  // запрос / очистка данных при изменении видимости
  useEffect(() => {
    if (!visible) {
      purgeModalFactory();
      setImportFile(undefined);
      return;
    }

    // важно! - resetFields ремаунтит все FormItem
    // в связи с этим используется когда форма ещё пустая
    form.resetFields();

    if (isCardModal) getData();
    if (modalType === ModalType.ATTACH)
      updateModalForm({ modalDetails: FormHandler.buildObjectData(parentForm, parentDetails) });
  }, [visible]);

  // при изменении modalDetails - проставляем значения в форму
  // должен идти после useEffect с visible
  useEffect(() => {
    setFormData(modalDetails);
  }, [modalDetails]);

  return (
    <Modal
      className={`${styles.modal} modal-withform`}
      open={modalVisible}
      title={title}
      okText={okText}
      cancelText={cancelText}
      onOk={modalOnOk}
      onCancel={modalOnCancel}
      footer={hideTools ? null : undefined}
      bodyStyle={modalBodyStyle}
      closable={true}
      centered={true}
      width={modalType !== ModalType.IMPORT_FILES ? '90%' : ''}
      destroyOnClose={true}
    >
      <Spin spinning={loading}>
        <Form
          id={'irkut'}
          className={styles.formContainer}
          form={form}
          layout='vertical'
          preserve={false}
          onValuesChange={onValuesChange}
        >
          <EntityEditorContext.Provider
            value={{
              className,
              form,
              cardForm,
              factory: modalFactory,
              documentId: getDocumentId(modalDetails),
              inModal: true,
              onValuesChange,
            }}
          >
            {isCardModal ? (
              <CardLayout
                factory={modalFactory}
                isNew={isNewObjectData(modalDetails)}
                inModal={true}
              />
            ) : modalType === ModalType.ATTACH ? (
              <CardTable
                tableId={attachTableId}
                simpleTable={true}
                withRowsSelect={true}
                component={{
                  params: attachParams,
                }}
              />
            ) : modalType === ModalType.IMPORT_FILES ? (
              <FilesList
                isInModal={true}
                ownProps={{ controlType: ControlType.FILES_LIST }}
                standalone={true}
                onFileSelect={addImportFile}
                standaloneFile={importFile}
                onFileDelete={() => setImportFile(undefined)}
                withoutDownload={true}
                withoutPreview={true}
              />
            ) : null}
          </EntityEditorContext.Provider>
        </Form>
      </Spin>
    </Modal>
  );
};

// изолированный компонент без пропсов
export default React.memo(ModalForm);
