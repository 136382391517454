import React from 'react';
import styles from './LogoWrap.module.css';
import logo from '../../../images/logo.svg';
import logoEn from '../../../images/logo.en.svg';
import logoCSDP from '../../../images/logo.csdp.svg';
import logoCSDPEn from '../../../images/logo.csdp.en.svg';
import { useTranslation } from 'react-i18next';

const LogoWrap: React.FC = () => {
  const {i18n} = useTranslation();
  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        {
          process.env.REACT_APP_TYPE === 'irk' ?
          i18n.language === 'en' ?
          <img src={logoEn} alt='irkut' /> :
          <img src={logo} alt='irkut' /> :
            i18n.language === 'en' ?
            <img src={logoCSDPEn} alt="csdp" /> :
            <img src={logoCSDP} alt="csdp" />
        }
      </div>
    </div>
  );
};

export default LogoWrap;
