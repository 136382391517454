import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const iconSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#D4F0FF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.3116 20.8837L31.986 11H8.42326L19.0977 20.8837C19.7302 21.5163 20.6791 21.5163 21.3116 20.8837ZM24.5535 20.4884L22.5767 22.307C21.9442 22.9395 21.0744 23.2558 20.2047 23.2558C19.3349 23.2558 18.4651 22.9395 17.7535 22.307L15.8558 20.4884L8.34419 28H32.0651L24.5535 20.4884ZM25.9767 19.2233L33.4093 26.6558V12.2651L25.9767 19.2233ZM14.4326 19.2233L7 12.2651V26.6558L14.4326 19.2233Z" fill="#004D77"/>
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={iconSvg} {...iconProps} />;
};

export default CustomIcon;
