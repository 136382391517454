import styles from './EntityList.module.css';
import InWorkIcon from 'components/icons/entityListIcons/InWorkIcon';
import ClosedIcon from 'components/icons/status/ClosedIcon';
import Line3Icons from 'components/icons/entityListIcons/Line3Icons';
import AssignPerformerIcon from 'components/icons/entityListIcons/AssignPerformerIcon';
import ResponseIcon from 'components/icons/entityListIcons/ResponseIcon';
import RefusedIcon from 'components/icons/entityListIcons/RefusedIcon';
import CanceledIcon from 'components/icons/status/CanceledIcon';
import DraftIcon from 'components/icons/status/DraftIcon';
import RequestIcon from 'components/icons/entityListIcons/RequestIcon';
import AdditionalInfoIcon from 'components/icons/AdditionalInfoIcon';
import RediscoveryIcon from 'components/icons/entityListIcons/RediscoveryIcon';
import MakingAdjustmentsIcon from 'components/icons/entityListIcons/MakingAdjustmentsIcon';
import PredReviewAwait from 'components/icons/entityListIcons/PredReviewAwait';
import ManagerReviewAwaitIcon from 'components/icons/entityListIcons/ManagerReviewAwaitIcon';
import ReviewAwaitIcon from 'components/icons/entityListIcons/ReviewAwaitIcon';
import OnAdjustmentEDIcon from 'components/icons/entityListIcons/OnAdjustmentEDIcon';
import AnnulIcon from 'components/icons/status/AnnulIcon';
import FinancialRequirementsIcon from 'components/icons/status/FinancialRequirementsIcon';
import DecisionMadeIcon from 'components/icons/status/DecisionMadeIcon';
import FormedIcon from 'components/icons/status/FormedIcon';
import ReadyToShipIcon from 'components/icons/status/ReadyToShipIcon';
import WaitForRegistrationIcon from 'components/icons/status/WaitForRegistrationIcon';
import WaitExtraInfoIcon from 'components/icons/status/WaitExtraInfoIcon';
import ContrAnswerIcon from 'components/icons/status/ContrAnswerIcon';
import MessageIcon from 'components/icons/status/MessageIcon';

export function handleIcon(status: string): JSX.Element {
  switch (status) {
    case 'В работе':
    case 'На рассмотрении':
      return <InWorkIcon className={styles.icon} />;
    case 'Закрыт':
    case 'Закрыто':
    case 'Принято':
      return <ClosedIcon className={styles.icon} />;
    case 'В проф. подразделении':
    case 'Проверка данных':
    case 'В работе ГИА':
    case 'В работе РАПАРТ':
    case 'В работе ОРЗЧ':
    case 'В работе ОК':
    case 'В работе ФПА':
    case 'В работе ДО':
    case 'Передано в бухгалтерию':
    case 'На комиссии':
      return <Line3Icons className={styles.icon} />;
    case 'Назначить исполнителя':
    case 'Направлено':
      return <AssignPerformerIcon className={styles.icon} />;
    case 'Ответ от проф. подразделения':
      return <ResponseIcon className={styles.icon} />;
    case 'Отказано':
    case 'Отклонено':
    case 'Подтверждено отклонение':
      return <RefusedIcon className={styles.icon} />;
    case 'Отменен':
    case 'Отменено':
    case 'Общая отмена':
      return <CanceledIcon className={styles.icon} />;
    case 'Черновик':
      return <DraftIcon className={styles.icon} />;
    case 'Запрос осн. информации':
    case 'Запрос осн. Информации':
    case 'Запрос доп. информации':
    case 'Запрос доп. Информации':
      return <RequestIcon className={styles.icon} />;
    case 'Получена осн. информация':
    case 'Получена осн. Информация':
    case 'Получена доп. информация':
    case 'Получена доп. Информация':
      return <AdditionalInfoIcon className={styles.icon} />;
    case 'Повторное открытие':
      return <RediscoveryIcon className={styles.icon} />;
    case 'Внесены корректировки':
      return <MakingAdjustmentsIcon className={styles.icon} />;
    case 'На корректировке ЭД':
      return <OnAdjustmentEDIcon className={styles.icon} />;
    case 'Аннулирован':
      return <AnnulIcon className={styles.icon} />;
    case 'Обработка фин. требований':
      return <FinancialRequirementsIcon className={styles.icon} />;
    case 'Принято решение':
    case 'Итоговое решение':
      return <DecisionMadeIcon className={styles.icon} />;
    case 'Сформировано':
      return <FormedIcon className={styles.icon} />;
    case 'Подготовлено к отправке':
      return <ReadyToShipIcon className={styles.icon} />;
    case 'Ожидает регистрации':
      return <WaitForRegistrationIcon className={styles.icon} />;
    case 'Ожидает доп. информации':
    case 'Ожидает доп. Информацию':
      return <WaitExtraInfoIcon className={styles.icon} />;
    case 'Ожидает рассмотрения полевым представителем':
      return <PredReviewAwait className={styles.icon}/>;
    case 'Ожидает рассмотрения СУР':
      return <ManagerReviewAwaitIcon className={styles.icon}/>;
    case 'Ожидает рассмотрения':
      return <ReviewAwaitIcon className={styles.icon}/>;
    case 'Получен ответ контрагента':
      return <ContrAnswerIcon className={styles.icon} />;
    case 'Отправлено':
      return <MessageIcon className={styles.icon} />;
    default:
      return <></>;
  }
}
