import React, { MouseEventHandler, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';
import { isArray, isBoolean } from 'lodash';

import ModalControl from 'components/controls/ModalControl/ModalControl';
import ModalCard from 'components/modalCard/ModalCard';
import GlobalLoaderContext from 'components/layout/loader/GlobalLoaderContext';
import ControlFactory from 'utils/ControlFactory';
import notification from 'components/messages/notification';

import styles from './EntityEditorButtons.module.css';

import {
  useModalForm,
  useEditorChanged,
  useRefreshComponent,
  useTabsCrud,
  useToolsCrud,
  useFormHandler,
} from 'hooks';

import ServerAPI from 'integration/ServerAPI';
import ServerError from 'integration/ServerError';
import FormatUtils from 'utils/FormatUtils';

import { AxiosResponseExt, AxiosResponseNull } from 'interfaces/AxiosResponseExt';
import { AppState } from 'store/reducers';
import { EntityEditorRouteParams } from 'router/Routes';
import { CardElem, DataModal } from 'utils/types';
import { handleIcon } from './IconsForButtons';
import { RefreshID } from 'enums/refresh';
import { ObjectData, SaveObjectExt } from 'interfaces/Object';
import { ActionType } from 'store/actionTypes';
import { getChildrenParentID } from 'utils/ObjectUtils';
import useModalWithText from 'hooks/useModalWithText';
import axios from 'axios';
import composeLink from 'utils/composeLink';
import { useTranslation } from 'react-i18next';

export type ControllerBPM = `BPM${string}`;

export enum ControllerList {
  SAVE_CASE = 'saveCaseTool',
  ADD_ANSWER = 'addAnswer',
  GET_NEWS_BY_ID = '/Landing/getNewsById',
  PARAMETER_REPORT = 'parameterReportTool',
  VIRTUAL_USE_AS_TEMPLATE = 'virtualUseAsTemplate',
  CREATE_TABLE_ITEM = 'createTableItem',
  CREATE_OBJECT = 'createObject',
  DELETE_DRAFT_OBJECT = '/crud/deleteObject',
  GEN_PASSWORD = 'GeneratePassword',
  EXPORT_ATTACHMENTS = 'contentCrud/getArchiveAllAttachedFiles'
}

export type Controller = ControllerList | ControllerBPM;

export const isBpmController = (controller?: Controller): boolean =>
  !!controller?.startsWith('BPM');

export interface EditorButton {
  label: string;
  action?: () => Promise<any>;
  loader?: (loading?: boolean) => void;
  toolName?: string;
  confirm?: boolean;
  controller?: Controller;
  disabled?: boolean;
  params: any;
}

interface EntityEditorButtonsProps {
  currentTab?: string;
  form?: any;
  factory?: ControlFactory;
  saveObject: SaveObjectExt;
  style?: any;
  className?: any;
  setTabsData?: (data: any) => void;
  setButtonsData?: (data: any) => void;
  inbox?: string
}

const EntityEditorButtons: React.FC<EntityEditorButtonsProps> = (props) => {
  const { form, factory, currentTab, saveObject, style, setTabsData, setButtonsData, inbox } = props;

  const {
    params: { className, objectID },
  } = useRouteMatch<EntityEditorRouteParams>();

  const dispatch = useDispatch();

  const editorDetails = useSelector((state: AppState) => state.editorDetails);

  const editorResponseSearchConfig = useSelector((state: AppState) => Object.keys(state.searchConfigurations).find(key => key.toLowerCase().includes('responce')) ? state.searchConfigurations[Object.keys(state.searchConfigurations).find(key => key.toLowerCase().includes('responce'))!] : null)

  const buttons: EditorButton[] =
    useSelector((state: AppState) => {
      const currTab = currentTab || state.editorButtons.currentTab;
      return currTab && state.editorButtons.buttons[currTab];
    }) || [];

  const globalLoader = useContext(GlobalLoaderContext);
  const { checkEditorChanged, checkEditorChangedOrNew, handleEditorChanged } = useEditorChanged({
    form,
    saveObject,
  });
  const { validateFields } = useFormHandler(form);

  const [isOpenModalPreview, setIsOpenModalPreview] = useState<boolean>(false);
  const [currentButton, setCurrentButton] = useState<any | null>();
  const { dataModal, getDataModal } = useModalWithText();
  const width = useWindowWidth();
  const [card, setCard] = useState<CardElem>({
    date: '',
    info: '',
    category: '',
    files: [],
    imgs: [],
    important: false,
    notes: null,
    title: '',
  });

  const { showModalCreate } = useModalForm();
  const { getTabsData } = useTabsCrud({ className, objectID });
  const { getToolsData } = useToolsCrud({ className, objectID });
  const { refreshComponent } = useRefreshComponent();
  const reloadEditorDetails = () => refreshComponent(RefreshID.EDITOR_DETAILS_DATA);
  const history = useHistory();
  const {t} = useTranslation();

  const launch = async (button: any, saveResult?: any) => {
    if (!button.action) return;
    button.loader(true);
    try {
      await button.action(saveResult);
      if (setTabsData) setTabsData((await getTabsData()) || {});
      if (setButtonsData) setButtonsData((await getToolsData()) || {});
      dispatch({
        type: ActionType.UPDATE_FILTERS,
        payload: {
          filtersId: className,
          filters: {},
        },
      });
    } catch (e) {
      notification.error(`${t('errorHappened')} ${e}`);
    } finally {
      button.loader(false);
    }
  };

  const globalLoaderTurnOff = () => {
    setCurrentButton(null);
    globalLoader.setLoading(false);
  };

  const getPreviewNews = async (button: EditorButton, id: string) => {
    const paramName = String(button.params.requiredParams[0]);

    const response: AxiosResponseNull = await ServerAPI.getNewsById({
      [paramName]: id,
    }).catch((serverError: ServerError) => serverError.notify(width));

    setCard(response?.data);
    setIsOpenModalPreview(true);
  };

  const deleteDraftObject = async () => {
    const response: AxiosResponseNull = await ServerAPI.deleteDraftObject({
      id: editorDetails.id,
      className: editorDetails.classType
    }).catch((serverError: ServerError) => serverError.notify(width));

    if (response?.status === 200) {
      history.push(
        composeLink({
          inbox: inbox,
          className: editorDetails.classType,
        })
      );
    }
  };

  useEffect(() => {
    if (currentButton !== null && currentButton !== undefined) {
      getDataModal(currentButton.params.toolName, width, checkEditorChangedOrNew(editorDetails));
    }
  }, [currentButton]);

  const getButtonOnClickHandler =
    (button: EditorButton): MouseEventHandler =>
      (event) => {
        event.preventDefault();

        if (!button || button.disabled) return;

        const { controller, label, action, params = {} } = button;
        if (
          checkEditorChangedOrNew(editorDetails) &&
          controller !== ControllerList.SAVE_CASE &&
          controller !== ControllerList.ADD_ANSWER &&
          controller !== ControllerList.CREATE_TABLE_ITEM &&
          controller !== ControllerList.CREATE_OBJECT &&
          controller !== ControllerList.GEN_PASSWORD &&
          controller !== ControllerList.EXPORT_ATTACHMENTS
        ) {
          setCurrentButton(button);
          return;
        }

        switch (controller) {
          case ControllerList.SAVE_CASE: {

            if (params.preFillObject && action) {
              if (params.preFillObject.isKwonledgeBase) {
                form.setFieldsValue(params.preFillObject);
                saveObject(params.toolName).then(() => globalLoaderTurnOff());
                return;
              }

              action().finally(() => globalLoaderTurnOff());
              return;
            }

            if (label === t('reopen') || params.showModal) {
              setCurrentButton(button);
              return;
            }

            // todo: usage
            saveObject(params.toolName).then((data) => {
              if (params.reloadTabsAndButtons && data) reloadEditorDetails();
              globalLoaderTurnOff();
            });
            return;
          }
          case ControllerList.GET_NEWS_BY_ID: {
            if (!checkEditorChanged()) {
              getPreviewNews(button, editorDetails.id);
              return;
            }
            break;
          }
          case ControllerList.DELETE_DRAFT_OBJECT: {
            setCurrentButton(button);
            break;
          }
          case ControllerList.CREATE_OBJECT:
          case ControllerList.CREATE_TABLE_ITEM: {
            handleEditorChanged(editorDetails).then((result) => {
              if (!result) return;

              const obj: ObjectData = isBoolean(result) ? editorDetails : result;

              const {
                className,
                tablePropName,
                fillValues,
                cardFillValues,
                refreshComponent,
                refreshCard,
                reloadTabsAndButtons,
                toolName,
                customLayout,
                layoutParams,
              } = params || {};

              const tableComponent = tablePropName && factory?.componentMap?.[tablePropName];
              const tableClassName = tableComponent?.params?.className;
              const objectClassName = className || tableClassName;
              const refreshIDs = (reloadTabsAndButtons && RefreshID.EDITOR_DETAILS_DATA) ||
                (refreshCard && RefreshID.EDITOR_DETAILS) || [refreshComponent, tablePropName];

              if (objectClassName) {
                showModalCreate({
                  objectClassName,
                  formValues: fillValues,
                  cardFormValues: cardFillValues,
                  cardForm: form,
                  refreshIDs,
                  toolName,
                  customLayout,
                  layoutParams,
                  parentDetails: obj,
                  parentId: getChildrenParentID(obj),
                  layoutParentId: obj?.id,
                  parentClassName: obj?.classType,
                });
              }
              return;
            });
            return;
          }
          case ControllerList.GEN_PASSWORD: {
            ServerAPI.axiosParamsPost(ControllerList.GEN_PASSWORD, {genPassword: true}).then((res) => {
              form.setFieldValue('Password', res.data.password);
              form.setFieldValue('PasswordRepeat', res.data.password);
              navigator.clipboard.writeText(res.data.password).then(() => {
                notification.success(res.data.message)
              })
            })
            return;
          }
          case ControllerList.EXPORT_ATTACHMENTS: {
            ServerAPI.axiosFormDataPost(ControllerList.EXPORT_ATTACHMENTS, {id: editorDetails.id, classType: editorDetails.classType, searchConfig: editorResponseSearchConfig.initConfig})
              .then((response: AxiosResponseExt) => {
                if (response.error) {
                  notification.error({
                    text: response.error,
                    width,
                  });
                } else {
                  const element = document.createElement('a');
                  element.setAttribute('href', `${response.data.file}`);
                  element.setAttribute('target', '_blank');
                  element.style.display = 'none';
                  document.body.appendChild(element);
                  element.click();
                  document.body.removeChild(element);
                  notification.success({
                    text: response.data.message,
                    width,
                  });
                }
              })
            return;
          }
        }

        if (!isBpmController(controller) && controller !== ControllerList.DELETE_DRAFT_OBJECT) {
          (async () => {
            if (
              controller !== ControllerList.PARAMETER_REPORT &&
              controller !== ControllerList.VIRTUAL_USE_AS_TEMPLATE
            ) {
              await saveObject();
            } else if (params.saveBefore) {
              await saveObject(params.toolName);
            }

            if (action) await action().catch(() => undefined);

            if (params.saveAfter) {
              await saveObject(params.toolName);
            }

            globalLoaderTurnOff();
          })();
          return;
        }

        if (form) {
          validateFields().then((check: boolean) => check && setCurrentButton(button));

          return;
        }

        setCurrentButton(button);
      };

  const modalOnOk = async () => {
    if (!currentButton) return;

    const { controller, params = {} } = currentButton;

    try {
      if (controller === ControllerList.DELETE_DRAFT_OBJECT) {
        deleteDraftObject()
      } else if (checkEditorChangedOrNew(editorDetails)) {
        globalLoader.setLoading(true);
        setCurrentButton(null);
        const saveResult = await saveObject(params.toolName);

        if (saveResult) {
          await launch(currentButton, saveResult);

          if (params.reloadTabsAndButtons) {
            reloadEditorDetails();
          }
          if (
            controller === ControllerList.GET_NEWS_BY_ID &&
            saveResult !== true &&
            saveResult.id
          ) {
            getPreviewNews(currentButton, saveResult.id);
          }
        }
      } else {
        if (params.showModal) {
          const data = await saveObject(params.toolName);

          if (params.reloadTabsAndButtons && data) {
            reloadEditorDetails();
          }
        } else {
          globalLoader.setLoading(true);
          setCurrentButton(null);
          if (editorDetails.params['.editorIsChanged']) {
            const saveResult = await saveObject(params.toolName);
            if (!saveResult) {
              globalLoaderTurnOff();
              return;
            }
          }
          await launch(currentButton);
        }
      }

      if (params.saveAfter) {
        await saveObject(params.toolName);
      }
    } catch (error) {
      console.error(error);
    } finally {
      globalLoaderTurnOff();
    }
  };

  const modalOnCancel = useCallback(() => setCurrentButton(null), []);

  return (
    <div>
      <ModalControl
        title={dataModal?.header}
        okText={dataModal?.ok}
        cancelText={dataModal?.cancel}
        okButtonProps={!currentButton}
        cancelButtonProps={!currentButton}
        isVisible={currentButton !== null && dataModal !== undefined && dataModal.header !== ''}
        onOk={modalOnOk}
        onCancel={modalOnCancel}
      >
        {isArray(dataModal?.text) ? (
          <div>
            {dataModal?.text.map((item: string, i: number) => {
              return (
                <div key={i} className={styles.modalText}>
                  {item}
                </div>
              );
            })}
          </div>
        ) : (
          <span>{dataModal?.text}</span>
        )}
      </ModalControl>
      <div className={styles.container}>
        {(buttons || []).map((button: EditorButton, index: number) => {
          return (
            <div
              key={`b${index}`}
              className={FormatUtils.getClassName([
                button.disabled ? styles.disabled : '',
                style ? styles.buttonDifferent : styles.button,
              ])}
              onClick={getButtonOnClickHandler(button)}
            >
              {style ? (
                <div className={styles.iconButton}>{handleIcon(button.controller)}</div>
              ) : (
                <></>
              )}
              <div className={style ? styles.buttonDifferentLabel : styles.button_label}>
                {button.label}
              </div>
            </div>
          );
        })}
      </div>
      <ModalCard
        isOpen={isOpenModalPreview}
        onClose={() => setIsOpenModalPreview(false)}
        card={card}
      />
    </div>
  );
};

export default EntityEditorButtons;
