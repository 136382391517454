import cn from 'classnames';
import styles from 'components/TableGrid/index.module.css';
import React, { FC } from 'react';
import {useTranslation} from 'react-i18next';

type TableNoResultsPropsType = {
  small?: boolean;
};

export const TableNoResults: FC<TableNoResultsPropsType> = ({ small = false }) => {
  const {t} = useTranslation();
  return (
  <div className={cn(styles.empty, { [styles.empty__small]: small })}>
    <h2 className={cn(styles.emptyTitle, { [styles.emptyTitleForCardTable]: small })}>
      {t("noEntries")}
    </h2>
    {!small && (
      <>
        <div className={styles.wrapper}>
          <div className={styles.topRow} />
          <div className={styles.topRow} />
          <div className={styles.topRow} />
          <div className={styles.topRow} />
          <div className={styles.topRow} />
          <div className={styles.topRow} />
        </div>
        <div className={styles.row} />
        <div className={styles.row} />
      </>
    )}
  </div>
  )
};
