import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import i18n from './i18n';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import appStore from 'store/store';
import { Provider } from 'react-redux';
import { ConfigProvider, Spin } from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import registerAxiosMiddleware from 'integration/axiosMiddleware';
import GlobalLoader from 'components/layout/loader/GlobalLoader';
import devToolsInit from 'utils/devTools';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import { I18nextProvider } from 'react-i18next';

moment.locale('ru');

if (process.env.NODE_ENV === 'development') devToolsInit(appStore);

ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloak}>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Provider store={appStore}>
          <ConfigProvider locale={ruRu}>
            <ErrorBoundary>
              <Suspense fallback={<Spin></Spin>}>
                <GlobalLoader>
                  <App />
                </GlobalLoader>
              </Suspense>
            </ErrorBoundary>
          </ConfigProvider>
        </Provider>
      </BrowserRouter>
    </I18nextProvider>
  </ReactKeycloakProvider>,
  document.getElementById('root')
);

registerAxiosMiddleware(appStore);
