import React, { useEffect, useState } from 'react';
import GlobalLoaderContext from './GlobalLoaderContext';
import styles from './GlobalLoader.module.css';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import i18n from 'i18n';

interface GlobalLoaderProps {
  children: any;
}

interface LoadingOverlayProps {
  loading: boolean;
}

const LoadingOverlay = (props: LoadingOverlayProps) => (
  <div className={`${styles.loadingOverlay} ${props.loading ? styles.loading : ''}`}>
    <Spin
      size='large'
      indicator={
        <div className={styles.spinIcon}>
          <LoadingOutlined style={{ fontSize: '100px' }} />
          <div className={styles.spinText}>{i18n.t('pleaseWait')}</div>
        </div>
      }
      spinning={props.loading}
    />
  </div>
);

const GlobalLoader: React.FC<GlobalLoaderProps> = (props) => {
  const { children } = props;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const rootEl: HTMLElement | null = document.getElementById('#root');
    if (rootEl !== null) {
      rootEl.style.position = loading ? 'relative' : 'initial';
    }
  }, [loading]);

  return (
    <GlobalLoaderContext.Provider
      value={{
        setLoading,
      }}
    >
      <LoadingOverlay loading={loading} />
      {children}
    </GlobalLoaderContext.Provider>
  );
};

export default GlobalLoader;
