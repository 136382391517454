import React from 'react';
import { Table } from 'antd';
import styles from './TableRequestsForThePeriod.module.css';
import FormatUtils from 'utils/FormatUtils';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';

interface TableRequestsForThePeriodProps {
  dataSource?: any;
  isRA?: boolean;
}

const handleColorBorder = (item: any) => {
  if (item === 'Итого' || item === 'Total') {
    return 'var(--color-dark-2)';
  } else if (item === 'AOG') {
    return 'var(--color-bright)';
  } else if (item === 'Critical') {
    return 'var(--color-bright-10)';
  } else if (item === 'Routine') {
    return 'var(--color-bright-5)';
  } else if (item === 'Work Stoppage') {
    return 'var(--color-bright-6)';
  }
};

export const handleTitle = (title: any) => {
  return (
    <div
      style={{
        textAlign: 'center',
        borderBottom: ` 2px solid ${handleColorBorder(title)} `,
      }}
    >
      {title}
    </div>
  );
};

const TableRequestsForThePeriod: React.FC<TableRequestsForThePeriodProps> = (props) => {
  const { Column, ColumnGroup } = Table;
  const { t } = useTranslation();

  const { isRA } = props;

  return (
    <div className={styles.wrap}>
      <Table
        className={styles.table}
        scroll={{ x: '100%' }}
        dataSource={props.dataSource}
        pagination={false}
        rowKey={() => {
          return uniqueId();
        }}
      >
        <Column
          title=''
          className={styles.columnMonth}
          dataIndex='time_period'
          key='time_period'
          render={(value: any) => <div>{`${new Date(value[0]).toLocaleDateString()}`}</div>}
        />
        <Column
          title={t('totalRequests')}
          className={styles.columnTotalInWort}
          dataIndex='total_requests'
          key='total_requests'
        />
        <ColumnGroup title={t('openAtBeginningOfPeriod')} className={styles.columnForBorder}>
          <Column
            title={handleTitle(t('total'))}
            dataIndex='open_at_the_beginning_of_the_period'
            key='open_at_the_beginning_of_the_period'
          />
          {
            !isRA ?
              <>
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='open_at_the_beginning_of_the_period_aog'
                  key='open_at_the_beginning_of_the_period_aog'
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='open_at_the_beginning_of_the_period_critical'
                  key='open_at_the_beginning_of_the_period_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='open_at_the_beginning_of_the_period_work_stoppage'
                      key='open_at_the_beginning_of_the_period_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  className={styles.columnForBorder}
                  dataIndex='open_at_the_beginning_of_the_period_routine'
                  key='open_at_the_beginning_of_the_period_routine'
                />
              </> : <></>
          }
        </ColumnGroup>
        {
          !isRA ?
            <>
              <ColumnGroup title={t('requestsReceived')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='created_requests'
                  key='created_requests'
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='created_requests_aog'
                  key='created_requests_aog'
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='created_requests_critical'
                  key='created_requests_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='created_requests_work_stoppage'
                      key='created_requests_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  className={styles.columnForBorder}
                  dataIndex='created_requests_routine'
                  key='created_requests_routine'
                />
              </ColumnGroup>
              <Column
                title={t('finished')}
                className={styles.columnForBorder}
                dataIndex='finished'
                key='finished'
              />
              <ColumnGroup title={t('finishedOnTime')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='finished_on_time'
                  key='finished_on_time'
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='finished_on_time_aog'
                  key='finished_on_time_aog'
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='finished_on_time_critical'
                  key='finished_on_time_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='finished_on_time_work_stoppage'
                      key='finished_on_time_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  className={styles.columnForBorder}
                  dataIndex='finished_on_time_routine'
                  key='finished_on_time_routine'
                />
              </ColumnGroup>
              <ColumnGroup title={t('finishedOutOfTime')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='finished_out_of_time'
                  key='finished_out_of_time'
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='finished_out_of_time_aog'
                  key='finished_out_of_time_aog'
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='finished_out_of_time_critical'
                  key='finished_out_of_time_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='finished_out_of_time_work_stoppage'
                      key='finished_out_of_time_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='finished_out_of_time_routine'
                  key='finished_out_of_time_routine'
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
              <ColumnGroup title={t("closed")} className={styles.columnForBorder}>
                <Column title={handleTitle(t('total'))} dataIndex='closed' key='closed' />
                <Column title={handleTitle('AOG')} dataIndex='closed_aog' key='closed_aog' />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='closed_critical'
                  key='closed_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='closed_work_stoppage'
                      key='closed_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='closed_routine'
                  key='closed_routine'
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
              <ColumnGroup title={t('declined')} className={styles.columnForBorder}>
                <Column title={handleTitle(t('total'))} dataIndex='denied' key='denied' />
                <Column title={handleTitle('AOG')} dataIndex='denied_aog' key='denied_aog' />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='denied_critical'
                  key='denied_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='denied_work_stoppage'
                      key='denied_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='denied_routine'
                  key='denied_routine'
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
              <ColumnGroup title={t('canceled')} className={styles.columnForBorder}>
                <Column title={handleTitle(t('total'))} dataIndex='сancelled' key='сancelled' />
                <Column title={handleTitle('AOG')} dataIndex='сancelled_aog' key='сancelled_aog' />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='сancelled_critical'
                  key='сancelled_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='сancelled_work_stoppage'
                      key='сancelled_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='сancelled_routine'
                  key='сancelled_routine'
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
              <Column
                title={t('notFinished')}
                dataIndex='not_finished'
                key='not_finished'
                className={styles.columnForBorder}
              />
              <ColumnGroup title={t('notFinishedProcessedOnTime')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='not_finished_processed_on_time'
                  key='not_finished_processed_on_time'
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='not_finished_processed_on_time_aog'
                  key='not_finished_processed_on_time_aog'
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='not_finished_processed_on_time_critical'
                  key='not_finished_processed_on_time_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='not_finished_processed_on_time_work_stoppage'
                      key='not_finished_processed_on_time_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='not_finished_processed_on_time_routine'
                  key='not_finished_processed_on_time_routine'
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
              <ColumnGroup title={t('incompleteOutOfTime')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='incomplete_out_of_time'
                  key='incomplete_out_of_time'
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='incomplete_out_of_time_aog'
                  key='reopened_in_this_period_aog'
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='incomplete_out_of_time_critical'
                  key='incomplete_out_of_time_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='incomplete_out_of_time_work_stoppage'
                      key='incomplete_out_of_time_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='incomplete_out_of_time_routine'
                  key='incomplete_out_of_time_routine'
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
              <Column
                title={t('reopenedRequests')}
                dataIndex='reopened_requests'
                key='reopened_requests'
                className={styles.columnForBorder}
              />
              <ColumnGroup title={t('reopenedAtBeginningOfPeriod')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='reopened_at_the_beginning_of_the_period'
                  key='reopened_at_the_beginning_of_the_period'
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='reopened_at_the_beginning_of_the_period_aog'
                  key='reopened_at_the_beginning_of_the_period_aog'
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='reopened_at_the_beginning_of_the_period_critical'
                  key='reopened_at_the_beginning_of_the_period_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='reopened_at_the_beginning_of_the_period_work_stoppage'
                      key='reopened_at_the_beginning_of_the_period_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='reopened_at_the_beginning_of_the_period_routine'
                  key='reopened_at_the_beginning_of_the_period_routine'
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
              <ColumnGroup title={t('reopenedInThisPeriod')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='reopened_in_this_period'
                  key='reopened_in_this_period'
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='reopened_in_this_period_aog'
                  key='reopened_in_this_period_aog'
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='reopened_in_this_period_critical'
                  key='reopened_in_this_period_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='reopened_in_this_period_work_stoppage'
                      key='reopened_in_this_period_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='reopened_in_this_period_routine'
                  key='reopened_in_this_period_routine'
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
              <Column
                title={t('averageProcessingTime')}
                dataIndex='average_processing_time'
                key='average_processing_time'
                className={styles.columnForBorder}
                render={(value: any) => {
                  if (value !== null && !isNaN(value)) {
                    return FormatUtils.formatSecondsToTime(value);
                  }
                  return value;
                }}
              />
              <ColumnGroup
                title={t('averageManufacturerProcessingTime')}
                className={styles.columnForBorder}
              >
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='average_manufacturer_processing_time'
                  key='average_manufacturer_processing_time'
                  render={(value: any) => {
                    if (value !== null && !isNaN(value)) {
                      return FormatUtils.formatSecondsToTime(value);
                    }
                    return value;
                  }}
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='average_manufacturer_processing_time_aog'
                  key='average_manufacturer_processing_time_aog'
                  render={(value: any) => {
                    if (value !== null && !isNaN(value)) {
                      return FormatUtils.formatSecondsToTime(value);
                    }
                    return value;
                  }}
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='average_manufacturer_processing_time_critical'
                  key='average_manufacturer_processing_time_critical'
                  render={(value: any) => {
                    if (value !== null && !isNaN(value)) {
                      return FormatUtils.formatSecondsToTime(value);
                    }
                    return value;
                  }}
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='average_manufacturer_processing_time_work_stoppage'
                      key='average_manufacturer_processing_time_work_stoppage'
                      render={(value: any) => {
                        if (value !== null && !isNaN(value)) {
                          return FormatUtils.formatSecondsToTime(value);
                        }
                        return value;
                      }}
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='average_manufacturer_processing_time_routine'
                  key='average_manufacturer_processing_time_routine'
                  render={(value: any) => {
                    if (value !== null && !isNaN(value)) {
                      return FormatUtils.formatSecondsToTime(value);
                    }
                    return value;
                  }}
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
              <ColumnGroup
                title={t('averageContractorProcessingTime')}
                className={styles.columnForBorder}
              >
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='average_contractor_processing_time'
                  key='average_contractor_processing_time'
                  render={(value: any) => {
                    if (value !== null && !isNaN(value)) {
                      return FormatUtils.formatSecondsToTime(value);
                    }
                    return value;
                  }}
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='average_contractor_processing_time_aog'
                  key='average_contractor_processing_time_aog'
                  render={(value: any) => {
                    if (value !== null && !isNaN(value)) {
                      return FormatUtils.formatSecondsToTime(value);
                    }
                    return value;
                  }}
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='average_contractor_processing_time_critical'
                  key='average_contractor_processing_time_critical'
                  render={(value: any) => {
                    if (value !== null && !isNaN(value)) {
                      return FormatUtils.formatSecondsToTime(value);
                    }
                    return value;
                  }}
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='average_contractor_processing_work_stoppage'
                      key='average_contractor_processing_work_stoppage'
                      render={(value: any) => {
                        if (value !== null && !isNaN(value)) {
                          return FormatUtils.formatSecondsToTime(value);
                        }
                        return value;
                      }}
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='average_contractor_processing_time_routine'
                  key='average_contractor_processing_time_routine'
                  className={styles.columnForBorder}
                  render={(value: any) => {
                    if (value !== null && !isNaN(value)) {
                      return FormatUtils.formatSecondsToTime(value);
                    }
                    return value;
                  }}
                />
              </ColumnGroup>
              <Column
                title={t('requestsWithQuery')}
                dataIndex='requests_with_query'
                key='requests_with_query'
                className={styles.columnForBorder}
              />
              <ColumnGroup title={t('requestsWithQueryDdmo')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='requests_with_query_ddmo'
                  key='requests_with_query_ddmo'
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='requests_with_query_ddmo_aog'
                  key='requests_with_query_ddmo_aog'
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='requests_with_query_ddmo_critical'
                  key='requests_with_query_ddmo_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='requests_with_query_ddmo_work_stoppage'
                      key='requests_with_query_ddmo_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='requests_with_query_ddmo_routine'
                  key='requests_with_query_ddmo_routine'
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
              <ColumnGroup
                title={t('requestsWithQueryInOtherDpt')}
                className={styles.columnForBorder}
              >
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='requests_with_query_in_other_dpt'
                  key='requests_with_query_in_other_dpt'
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='requests_with_query_in_other_dpt_aog'
                  key='requests_with_query_in_other_dpt_aog'
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='requests_with_query_in_other_dpt_critical'
                  key='requests_with_query_in_other_dpt_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='requests_with_query_in_other_dpt_work_stoppage'
                      key='requests_with_query_in_other_dpt_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='requests_with_query_in_other_dpt_routine'
                  key='requests_with_query_in_other_dpt_routine'
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
              <ColumnGroup
                title={t('requestsWithQueryInContractor')}
                className={styles.columnForBorder}
              >
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='requests_with_query_in_contractor'
                  key='requests_with_query_in_contractor'
                />
                <Column
                  title={handleTitle('AOG')}
                  dataIndex='requests_with_query_in_contractor_aog'
                  key='requests_with_query_in_contractor_aog'
                />
                <Column
                  title={handleTitle('Critical')}
                  dataIndex='requests_with_query_in_contractor_critical'
                  key='requests_with_query_in_contractor_critical'
                />
                {
                  process.env.REACT_APP_TYPE !== 'irk' ?
                    <Column
                      title={handleTitle('Work Stoppage')}
                      dataIndex='requests_with_query_in_contractor_work_stoppage'
                      key='requests_with_query_in_contractor_work_stoppage'
                    /> : <></>
                }
                <Column
                  title={handleTitle('Routine')}
                  dataIndex='requests_with_query_in_contractor_routine'
                  key='requests_with_query_in_contractor_routine'
                  className={styles.columnForBorder}
                />
              </ColumnGroup>
            </> : <>
              <ColumnGroup title={t('RAReceived')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='created_requests'
                  key='created_requests'
                />
              </ColumnGroup>
              <Column
                title={t('finished')}
                className={styles.columnForBorder}
                dataIndex='finished'
                key='finished'
              />
              <ColumnGroup title={t('finishedOnTime')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='finished_on_time'
                  key='finished_on_time'
                />
              </ColumnGroup>
              <ColumnGroup title={t('finishedOutOfTime')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='finished_out_of_time'
                  key='finished_out_of_time'
                />
              </ColumnGroup>
              <ColumnGroup title={t("closed")} className={styles.columnForBorder}>
                <Column title={handleTitle('')} dataIndex='closed' key='closed' />
              </ColumnGroup>
              <ColumnGroup title={t('declined')} className={styles.columnForBorder}>
                <Column title={handleTitle(t('total'))} dataIndex='denied' key='denied' />
              </ColumnGroup>
              <ColumnGroup title={t('canceled')} className={styles.columnForBorder}>
                <Column title={handleTitle(t('total'))} dataIndex='сancelled' key='сancelled' />
              </ColumnGroup>
              <Column
                title={t('notFinished')}
                dataIndex='not_finished'
                key='not_finished'
                className={styles.columnForBorder}
              />
              <ColumnGroup title={t('notFinishedProcessedOnTime')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='not_finished_processed_on_time'
                  key='not_finished_processed_on_time'
                />
              </ColumnGroup>
              <ColumnGroup title={t('incompleteOutOfTime')} className={styles.columnForBorder}>
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='incomplete_out_of_time'
                  key='incomplete_out_of_time'
                />
              </ColumnGroup>
              <Column
                title={t('averageProcessingTime')}
                dataIndex='average_processing_time'
                key='average_processing_time'
                className={styles.columnForBorder}
                render={(value: any) => {
                  if (value !== null && !isNaN(value)) {
                    return FormatUtils.formatSecondsToTime(value);
                  }
                  return value;
                }}
              />
              <ColumnGroup
                title={t('averagePprocessingTimeByCorporation')}
                className={styles.columnForBorder}
              >
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='average_manufacturer_processing_time'
                  key='average_manufacturer_processing_time'
                  render={(value: any) => {
                    if (value !== null && !isNaN(value)) {
                      return FormatUtils.formatSecondsToTime(value);
                    }
                    return value;
                  }}
                />
              </ColumnGroup>
              <ColumnGroup
                title={t('averageProcessingTimeByCustomer')}
                className={styles.columnForBorder}
              >
                <Column
                  title={handleTitle(t('total'))}
                  dataIndex='average_contractor_processing_time'
                  key='average_contractor_processing_time'
                  render={(value: any) => {
                    if (value !== null && !isNaN(value)) {
                      return FormatUtils.formatSecondsToTime(value);
                    }
                    return value;
                  }}
                />
              </ColumnGroup>
              <ColumnGroup
                title={t('totalRequestsToTheCustomer')}
                className={styles.columnForBorder}
              >
                <></>
              </ColumnGroup>
            </>
        }
      </Table>
    </div>
  );
};

export default TableRequestsForThePeriod;
